import {IContainer} from "bottlejs";
import ModuleService from "@/core/services/module.service";
import VueRouter, {RouteConfig} from "vue-router";
import {Store} from "vuex";
import Vue from "vue";
import {vuexBind} from "@/utils/store";
import Application from "@/core/application";

export default function initializeRouter(this: Application): VueRouter {

  let modules: ModuleService = this.getService('module');
  let store: Store<any> = this.getService('store');

  Vue.use(VueRouter);

  let coreRoutes: RouteConfig[] = [
    {
      name: 'out-dataverse',
      path: '/out/dataverse',
      beforeEnter() { location.href = 'https://dataverse.metro1.se2a.ing.tu-bs.de/' }
    }
  ];
  let adminRoutes: RouteConfig[] = [];
  let unitRoutes: RouteConfig[] = [];
  for (let module of modules.getRegistry().values()) {
    if(typeof module.coreRoutes !== 'undefined') {
      coreRoutes.push(...module.coreRoutes);
    }

    if(typeof module.unitRoutes !== 'undefined') {
      unitRoutes.push(...module.unitRoutes);
    }

    if(typeof module.adminRoutes !== 'undefined') {
      adminRoutes.push(...module.adminRoutes);
    }
  }


  let router = new VueRouter({
    mode: 'hash',
    routes: [
      ...coreRoutes,
      {
        name: 'se2a-admin',
        path: '/admin',
        component: () => import('@/utils/DelegatingView.vue'),
        meta: {
          layout: 'admin',
          requiresAuth: true,
          requiresAdmin: true,
        },
        children: [
          ...adminRoutes
        ]
      },
      {
        path: '/:unitId',
        component: () => import('@/utils/DelegatingView.vue'),
        meta: {
          requiresAuth: true
        },
        props: route => ({unit: store.getters['unit/findByRouteId'](route.params.unitId)}),
        children: [
          ...unitRoutes
        ]
      }
    ],
  });

  router.beforeEach(async (to, from, next) => {
    await store.commit('core/startLoading');
    if (to.name === null) {
      next({name: 'auth-signin'});
      return;
    }

    if (to.meta?.requiresAuth) {
      if (!store.getters['auth/isAuthenticated']) {
        next({name: 'auth-signin'})
      } else {
        next()
      }
    } else {
      next()
    }
  });

  router.beforeEach((to, from, next) => {
    if(to.meta?.requiresAdmin ?? false) {
      if (!store.getters['auth/adminAccess'] ) {
        next({name: 'auth-signin'})
      } else {
        next()
      }
    } else {
      next();
    }
  });

  router.afterEach(async (to, from) => {

    // find route param changes (which cannot be evaluated in matched segments!)
    const changedParams = Object.keys(to.params).filter(k => {
      return to.params[k] != from.params[k]
    })

    // find matches which changed
    const matchIndex = to.matched.findIndex((r, i) => {
      return r.name !== (from.matched[i] || {}).name ||
        changedParams.some(p => r.path.includes(`/:${p}`))
    })

    // abort early if route is identical
    if (matchIndex === -1) {
      await store.commit('core/stopLoading');
      return;
    }

    // After Leave Actions
    await dispatchActions(from.matched.slice(matchIndex).reverse().map(m => m.meta.afterLeave), to, from);

    // Before Enter Actions
    await dispatchActions(to.matched.slice(matchIndex).map(m => m.meta.beforeEnter), to, from);

    //TODO: Add possibility to modify or cancel route change from a store!

    await store.commit('core/stopLoading');
  });

  async function dispatchActions(actions, to, from) {
    for (const action of [].concat(...actions)) {
      if (action) {
        await store.dispatch(action, {
          //fuck you strings/integers
          ...parseAsIntegers(to.params),
          routeFrom: from,
          routeTo: to,
        })
      }
    }
  }

  function parseAsIntegers(obj) {
    return Object.entries(obj).reduce((acc, [k, v]) => {
      // @ts-ignore
      acc[k] = parseInt(v, 10)
      return acc
    }, {})
  }

  return router;
}
