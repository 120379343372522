import ModuleService from "@/core/services/module.service";
import Vuex, {Plugin} from "vuex";
import Vue from "vue";
import Application from "@/core/application";

export default function initializeStore(this: Application) {
  let modules: ModuleService = this.getService('module');

  Vue.use(Vuex);

  const vuexModules = {};
  for (let module of modules.getRegistry().values()) {
    Object.assign(vuexModules, module.stores);
  }

  let vuexPlugins: Plugin<unknown>[] = [];
  for (let module of modules.getRegistry().values()) {
    if(typeof module.storePlugins !== 'undefined') {
      vuexPlugins.push(...module.storePlugins);
    }
  }

  return new Vuex.Store({
    modules: vuexModules,
    plugins: vuexPlugins
  });
}
