import {createModule} from "@/store/utils";
import {createOldEntityCacheModule, MUTATIONS} from "@/store/module";
import {CLIENT} from "@/api/client";

function initialState() {
  return {
    active: undefined,
  }
}

export default createModule(createOldEntityCacheModule('publications'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async select({commit, dispatch}, ctx) {
      const active = await dispatch('fetchById', ctx.publicationId);

      for (const goal of active.goals) {
        await dispatch('publicationUnitGoal/fetchByIri', goal, { root: true });
      }

      await commit('setActive', active);
    }
  },
  getters: {
    activeRouteIri(state, getters, rootState, rootGetters) {
      if(!rootState.route.params.publicationId) {
        return undefined;
      }

      return '/api/publications/' + rootState.route.params.publicationId;
    },
    activeRouteId(state, getters, rootState, rootGetters) {
      if(!rootState.route.params.publicationId) {
        return undefined;
      }

      return rootState.route.params.publicationId;
    },
    active(state, getters, rootState, rootGetters) {
      if(!state.active) return undefined;
      return {
        ...state.active,
        goals: state.active.goals.map(g => rootGetters['publicationUnitGoal/findEnriched'](g))
      }
    }
  },
  mutations: {
    setActive(state, active) {
      state.active = active;
    },
    clearActive(state) {
      state.active = undefined;
    }
  }
});
