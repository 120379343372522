import Intranet from '@/core/intranet';

import IssueChangeModal from './components/issue-change-modal/IssueChangeModal.vue';
import WorkPackageChangeModal from './components/work-package-change-modal/WorkPackageChangeModal.vue';

import UnitTypeStore from './stores/unitType';
import WorkPackageStore, {storeListener} from './stores/workPackage';
import RelationStore from './stores/relation';
import RelationLogStore from './stores/relationLog';
import IssueStore from './stores/issue';
import TeamStore from './stores/team';

import routes from "./routes";

Intranet.modules().registerModule('unit', {
  stores: {
    unitType: UnitTypeStore,
    issue: IssueStore,
    relation: RelationStore,
    relationLog: RelationLogStore,
    workPackage: WorkPackageStore,
    team: TeamStore
  },
  storePlugins: [
    storeListener
  ],
  coreRoutes: [
    {
      name: 'wp-redirect',
      path: '/wp-redirect/:wpId',
      redirect: to => {
        let unitId = Intranet.store().getters['workPackage/findUnitIdByWorkPackageRouteId'](to.params.wpId);
        return { name: 'work-package-details', params: {unitId: unitId, wpId: to.params.wpId}};
      },
    }
  ],
  unitRoutes: routes
});

Intranet.modals().registerModal('issue-change-modal', IssueChangeModal, "1000px");
Intranet.modals().registerModal('work-package-change-modal', WorkPackageChangeModal, "1000px");
