import {createModule} from "@/store/utils";
import {createEntityCacheModule, MUTATIONS} from "@/store/module";
import {CLIENT} from "@/api/client";

function initialState() {
  return {}
}

export default createModule(createEntityCacheModule('memberships'), {
  namespaced: true,
  state: initialState(),
  actions: {

    async fetchByTeam({commit, dispatch}, team) {
      if(typeof team === 'object') {
        team = team['@id'];
      }

      const memberships = await CLIENT
        .go(team + '/memberships')
        .addPaginationEnabledModifier(false)
        .get();

      await commit(MUTATIONS.ADD_MULTIPLE, memberships.asPaginationResult().membersData());
    }
  },
  getters: {
  },
  mutations: {

  }
});
