<template>

  <v-card>
    <v-card-title>Upload signed Qualification Plan</v-card-title>
    <v-card-text>

      <v-file-input
          v-model="files"
          counter
          label="Files"
          multiple
          placeholder="Select your files"
          prepend-icon="mdi-paperclip"
          outlined
          :show-size="1000"
      >
        <template v-slot:selection="{ index, text }">
          <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
          >
            {{ text }}
          </v-chip>

          <span
              v-else-if="index === 2"
              class="text-overline grey--text text--darken-3 mx-2"
          >
            +{{ files.length - 2 }} File(s)
          </span>
        </template>
      </v-file-input>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn outlined
             color="green"
             :disabled="files.length === 0"
             :loading="isUploading"
             @click="submit"
      >Submit</v-btn>
      <v-btn outlined color="red" @click="close">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {defineComponent, ref} from "vue";
import Intranet from "@/core/intranet";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import {useGetters} from "vuex-composition-helpers";

export default defineComponent({
  name: "qualification-plan-signed-upload-modal",
  setup() {
    const { props } = useGetters('modal', ['props']);

    const files = ref([]);

    const close = () => {
      Intranet.modals().closeCurrentModal();
    }

    const isUploading = ref(false);
    const submit = () => {
      isUploading.value = true;
      let promises = [];

      for (const file of files.value) {
        let data = new FormData();
        data.append('file', file);
        promises.push(CLIENT.go(byApi('qualification_plan_signed_files')).postRaw(data, null));
      }

      Promise.all(promises).then(serverFiles => {
        CLIENT.go(props.value.plan['@id']).patch({
          signedFiles: serverFiles.map(f => f.asData()['@id'])
        }).then(fprResult => {
          isUploading.value = false;
          Intranet.modals().closeCurrentModal();
        })
      })
    }

    return {
      files,
      close,
      isUploading,
      submit,
      props
    }
  }
});
</script>

<style scoped>

</style>
