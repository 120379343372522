<template>
  <div class="my-2">
    <div v-if="!isLoading">
      <v-card>
        <v-card-title>Qualification Plan Category: {{ qp.name }}</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column flex-sm-row">
            <div class="flex-grow-1 pt-2 pa-sm-2">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="qp.name" label="Name" placeholder="name"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="qp.displayName" label="displayName" placeholder="displayName"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Description"
                    v-model="qp.description"
                  />
                </v-col>
              </v-row>
            </div>
            <div>
              <!-- Actions -->

              <v-btn @click="save">Save</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import {defineComponent, watchEffect} from "vue";
import {ref} from "vue";
import {CLIENT} from "@/api/client";
import {byApiId} from "@/api/helpers";

export default defineComponent({
  props: {
    id: Number|String
  },
  setup(props) {
    const isLoading = ref(true);

    const qp = ref({});
    const fetchQualificationPlanCategories = () => {
      isLoading.value = true;
      CLIENT.go(byApiId('qualification_plan_categories', props.id)).get().then(result => {
        qp.value = result.asData();
      }).finally(() => {
        isLoading.value = false;
      })
    }

    watchEffect(fetchQualificationPlanCategories);

    const save = () => {
      CLIENT.go(qp.value['@id']).patch({
        name: qp.value.name,
        displayName: qp.value.displayName,
        description: qp.value.description
      });
    }

    return {
      qp,
      isLoading,
      save
    }
  }
});
</script>

<style scoped>

</style>
