import {buildOperations, buildQuery, createModule, handleError} from "./utils";
import remove from 'lodash/remove';
import Vue from "vue";
import {createVuexApiService} from '@/api/vuex'
import {byApiId} from "@/api/helpers";

export const MUTATIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  ENSURE_LOAD: 'ENSURE_LOAD',
  ADD: 'ADD',
  ADD_MULTIPLE: 'ADD_MULTIPLE',
  SET_ERROR: 'SET_ERROR',
  START_LOADING: 'START_LOADING',
  END_LOADING: 'END_LOADING',
  SET_ACTIVE: 'SET_ACTIVE',
  SET_LIST_ITEMS: 'SET_LIST_ITEMS',
  SET_LIST_VIEW: 'SET_LIST_VIEW',
  SET_LIST_TOTAL_ITEMS: 'SET_LIST_TOTAL_ITEMS',
};

export function createLoadingModule() {
  return {
    namespaced: true,
    state: {
      loading: 0,
    },
    getters: {
      isLoading: state => {
        return state.loading > 0;
      }
    },
    mutations: {
      [MUTATIONS.START_LOADING]: state => {
        state.loading++;
      },
      [MUTATIONS.END_LOADING]: state => {
        if (state.loading > 0) {
          state.loading--;
        }
      }
    }
  };
}

export function createEntityCacheModule(serviceName) {

  const service = createVuexApiService(serviceName);
  return createModule(createLoadingModule(), {
    namespaced: true,
    state: {
      // cache
      cache: {},
      routeIdCache: {},

      // error
      error: null,
      violations: null,
    },
    actions: {

      // =======================
      // Add entities to cache =
      // =======================

      add: ({commit}, data) => {
        commit(MUTATIONS.ADD, data);
      },
      addMultiple: ({commit}, data) => {
        commit(MUTATIONS.ADD_MULTIPLE, data);
      }
    },
    getters: {
      // Getters
      find: (state) => (id) => {
        return state.cache[id];
      },
      findByRouteId: (state, getters) => (routeId) => {
        return getters.find(state.routeIdCache[routeId]);
      },
      items: (state, getters) => {
        return Object.values(state.cache);
      },

      // Error
      error: (state) => {
        return state.error;
      },
      violations: (state) => {
        return state.violations;
      }
    },
    mutations: {
      [MUTATIONS.ADD]: (state, item) => {
        Vue.set(state.cache, item['id'], item);
        let routeId = item['id'].split('/').pop();
        Vue.set(state.routeIdCache, routeId, item['id']);
      },
      [MUTATIONS.ADD_MULTIPLE]: (state, items) => {
        for (let item of items) {
          Vue.set(state.cache, item['id'], item);
          let routeId = item['id'].split('/').pop();
          Vue.set(state.routeIdCache, routeId, item['id']);
        }
      },

      // Error
      [MUTATIONS.SET_ERROR]: (state, error) => {
        state.error = error;
      }
    }
  });
}


export function createActiveEntityItemModule(serviceName) {
  return createModule(createEntityCacheModule(serviceName), {
    namespaced: true,
    state: {
      activeIri: '',
    },
    actions: {
      setActive: ({commit}, data) => {
        if (typeof data == 'object' && data !== null) {
          commit(MUTATIONS.SET_ACTIVE, data['id']);
        } else {
          commit(MUTATIONS.SET_ACTIVE, data);
        }
      }
    },
    getters: {
      active: (state, getters) => {
        return getters.find(state.activeIri);
      }
    },
    mutations: {
      [MUTATIONS.SET_ACTIVE]: (state, item) => {
        state.activeIri = item;
      }
    }
  })
}

export function createOldEntityCacheModule(serviceName) {

  const service = createVuexApiService(serviceName);
  return createModule(createLoadingModule(), {
    namespaced: true,
    state: {
      // cache
      byIri: {},
      allIds: [],

      // ensure
      ensured: [],
      fetchedAll: false,

      // list
      listItems: [],
      listView: [],
      listTotalItems: 0,

      // internal
      error: null,
      violations: null,
      deleted: null,

    },
    actions: {

      // ============
      // Operations =
      // ============

      create: ({commit}, payload) => {
        commit(MUTATIONS.SET_ERROR, '');
        commit(MUTATIONS.START_LOADING);

        return service.create(payload)
          .then(async response => {
            commit(MUTATIONS.CREATE, response.asData());
            return response.asData();
          })
          .catch(e => handleError(commit, e))
          .finally(() => commit(MUTATIONS.END_LOADING))
      },

      update: ({commit}, payload) => {
        commit(MUTATIONS.SET_ERROR, '');
        commit(MUTATIONS.START_LOADING);

        return service.update(payload)
          .then(response => {
            commit(MUTATIONS.UPDATE, response.rawData);
          })
          .catch(e => handleError(commit, e))
          .finally(() => commit(MUTATIONS.END_LOADING))
      },

      delete: ({commit}, payload) => {
        commit(MUTATIONS.SET_ERROR, '');
        commit(MUTATIONS.START_LOADING);

        return service.delete(payload)
          .then(response => {
            commit(MUTATIONS.DELETE, payload);
          })
          .catch(e => handleError(commit, e))
          .finally(() => commit(MUTATIONS.END_LOADING))
      },

      // ========
      // Ensure =
      // ========
      ensureLoadedByIri: ({commit, dispatch, state}, iri) => {
        if (state.ensured.includes(iri)) {
          return;
        }

        commit(MUTATIONS.ENSURE_LOAD, iri);
        if (state.allIds.filter(item => item['@id'] === iri).length === 0) {
          return dispatch('fetchByIri', iri);
        }
      },

      // ==============
      // Actual fetch =
      // ==============

      async fetchById({commit, state, dispatch, getters}, id) {
        const iri = byApiId(serviceName, id);
        return await dispatch('fetchByIri', iri);
      },

      async fetchByIri({commit, state, getters}, iri) {
        if (state.ensured.includes(iri) || typeof getters.find(iri) !== "undefined") {
          return getters.find(iri);
        } else {
          await commit(MUTATIONS.ENSURE_LOAD, iri);
        }

        const response = await service.fetchByIri(iri);
        commit(MUTATIONS.ADD, response.asData());
        return getters.find(iri);
      },

      async fetchByIriForce({commit, state, getters}, iri) {
        const response = await service.fetchByIri(iri);
        commit(MUTATIONS.ADD, response.asData());
        return getters.find(iri);
      },

      async fetchAll({commit, state}): Promise<void> {
        return new Promise((resolve, reject) => {
          if (state.fetchedAll) {
            resolve();
            return;
          }

          commit(MUTATIONS.START_LOADING);
          commit("FETCH_ALL");
          service.fetchAll({pagination: false})
            .then(response => {
              response.asPaginationResult().membersData().forEach(item => {
                commit(MUTATIONS.ADD, item);
              })

              resolve();
            })
            .catch(error => {
              commit(MUTATIONS.SET_ERROR, error);
              reject();
            })
            .finally(() => commit(MUTATIONS.END_LOADING));
        })
      },

      // ============
      // List tools =
      // ============

      fetchPage: ({commit}, params) => {
        commit(MUTATIONS.START_LOADING);
        params.pagination = true;
        service.fetchAll(params)
          .then(response => {
            commit(MUTATIONS.SET_LIST_ITEMS, response.asPaginationResult().membersData());
            commit(MUTATIONS.SET_LIST_TOTAL_ITEMS, response.asPaginationResult().totalCount());
          })
          .catch(error => commit(MUTATIONS.SET_ERROR, error))
          .finally(() => commit(MUTATIONS.END_LOADING));
      },

      // ==========
      // Internal =
      // ==========

      add: ({commit}, data) => {
        commit(MUTATIONS.ADD, data);
      },
      addMultiple: ({commit}, data) => {
        commit(MUTATIONS.ADD_MULTIPLE, data);
      }
    },
    getters: {
      // Basic getters
      find: (state) => (iri) => {
        return state.byIri[iri];
      },
      findById: (state, getters) => (id) => {
        return getters.find(byApiId(serviceName, id))
      },
      items: (state, getters) => {
        return state.allIds.map(iri => getters.find(iri));
      },

      // List getters
      listItems: (state) => {
        return state.listItems;
      },
      listView: (state) => {
        return state.listView;
      },
      listTotalCount: (state) => {
        return state.listTotalItems;
      },

      // Error
      error: (state) => {
        return state.error;
      },
      violations: (state) => {
        return state.violations;
      }
    },
    mutations: {
      // Operations
      [MUTATIONS.CREATE]: (state, item) => {
        //console.log(item);
        Vue.set(state.byIri, item['@id'], item);
        if (state.allIds.includes(item['@id'])) return;
        state.allIds.push(item['@id']);
      },
      [MUTATIONS.DELETE]: (state, deleted) => {
        Object.assign(state, {
          allIds: remove(state.allIds, iri => iri === deleted['@id']),
          byIri: remove(state.byIri, iri => iri === deleted['@id']),
        })
      },
      [MUTATIONS.UPDATE]: (state, item) => {
        Vue.set(state.byIri, item['@id'], item);
      },

      // Ensure
      [MUTATIONS.ENSURE_LOAD]: (state, ensure) => {
        state.ensured.push(ensure);
      },
      ['FETCH_ALL']: (state) => {
        state.fetchedAll = true;
      },

      // List
      [MUTATIONS.SET_LIST_ITEMS]: (state, items) => {
        state.listItems = items;
      },
      [MUTATIONS.SET_LIST_VIEW]: (state, view) => {
        state.listView = view;
      },
      [MUTATIONS.SET_LIST_TOTAL_ITEMS]: (state, totalItems) => {
        state.listTotalItems = totalItems;
      },

      // Reset


      // Internal
      [MUTATIONS.ADD]: (state, item) => {
        Vue.set(state.byIri, item['@id'], item);
        if (state.allIds.includes(item['@id'])) return;
        state.allIds.push(item['@id']);
      },
      [MUTATIONS.ADD_MULTIPLE]: (state, items) => {
        for (let item of items) {
          Vue.set(state.byIri, item['@id'], item);
          if (state.allIds.includes(item['@id'])) return;
          state.allIds.push(item['@id']);
        }
      },

      // Error
      [MUTATIONS.SET_ERROR]: (state, error) => {
        state.error = error;
      }
    }
  });
}
