export default class SubmissionError extends Error {

  //TODO: specify type
  private errors: any

  constructor(errors) {
    super('Submit Validation Failed');
    this.errors = errors;
    this.name = this.constructor.name;

    return this;
  }
}
