<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <div ref="copylabel" v-on="on" class="copylabel animate__faster" @click.stop.prevent="copy">{{ text }}</div>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
/*
|---------------------------------------------------------------------
| Copy Label Component
|---------------------------------------------------------------------
|
| Copy to clipboard with the plugin clipboard `this.$clipboard`
|
*/
export default {
  name: "CopyLabel",
  props: {
    // Text to copy to clipboard
    text: {
      type: String,
      default: ''
    },
    // Text to show on toast
    toastText: {
      type: String,
      default: 'Copied to clipboard!'
    },
  },
  data() {
    return {
      tooltip: 'Copy',
      timeout: null
    }
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout)
  },
  methods: {
    copy() {
      this.$copyText(this.text).then(value => {
        this.tooltip = 'Copied!'
        this.timeout = setTimeout(() => {
          this.tooltip = 'Copy'
        }, 2000)
      })
    }
  }
}
</script>

<style scoped>
.copylabel {
  cursor: pointer;
  display: inline-block;
  border-bottom: 1px dashed;
}
</style>
