import Intranet from "@/core/intranet";
import QualificationPlanProofUploadModal
  from "@/modules/qualification-plan/component/qualification-plan-proof-upload-modal/qualification-plan-proof-upload-modal.vue";
import QualificationPlanSignedUploadModal
  from "@/modules/qualification-plan/component/qualification-plan-signed-upload-modal/qualification-plan-signed-upload-modal.vue";


// register modal
Intranet.modals().registerModal('qualification-plan-proof-upload-modal', QualificationPlanProofUploadModal)
Intranet.modals().registerModal('qualification-plan-signed-upload-modal', QualificationPlanSignedUploadModal)

// register module
Intranet.modules().registerModule('qualification-plan', {
  coreRoutes: [
    {
      name: 'qualification-plan-index',
      path: '/qualification-plan',
      component: () => import('@/modules/qualification-plan/pages/qualification-plan-index/qualification-plan-index.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      name: 'qualification-plan-edit',
      path: '/qualification-plan/:id/edit',
      component: () => import('@/modules/qualification-plan/pages/qualification-plan-edit/qualification-plan-edit.vue'),
      meta: {
        requiresAuth: true
      },
      props: route => ({ qualificationPlanId: route.params.id })
    }
  ]
});
