import {createModule} from "@/store/utils";
import {createOldEntityCacheModule} from "@/store/module";

function initialState() {
  return {}
}

export default createModule(createOldEntityCacheModule('publication_goals'), {
  namespaced: true,
  state: initialState(),
  actions: {

  },
  getters: {

  },
  mutations: {

  }
});
