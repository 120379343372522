import {Client} from "@/api/client";
import {Resource} from "@/api/resource";
import {Paginator} from "@/api/paginator";

export class Result<T = any> {
  client: Client;
  rawData: any;
  uri: string;

  constructor(client: Client, rawData: any, uri: string) {
    this.client = client;
    this.rawData = rawData;
    this.uri = uri;
  }

  asData(): T {
    return this.rawData as T;
  }

  asPaginationResult(): Paginator<T> {
    return new Paginator<T>(this);
  }

  asResource(): Resource<T> {
    return this.client.go(this.rawData['@iri']);
  }

  followRelation<O>(prop: string): Resource<O> {
    return this.client.go(this.rawData[prop]);
  }
}
