<template>
  <div>
    <v-card v-if="isReady">

      <div class="pa-2 d-flex justify-space-between" v-for="change of changes">
        <div class="info-container">
          {{ change.modalInfo }}
        </div>
        <component class="type-container" :is="typeComponent" :item="change" />
      </div>

      <v-divider></v-divider>
      <v-card-actions style="display: flex; justify-content: end">
        <v-btn @click="close" outlined>Close</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      Loading..
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Intranet from "@/core/intranet";
import {CLIENT} from "@/api/client";

import issueType from '@/modules/change/components/types/Issue.vue';
import workPackageType from '@/modules/change/components/types/WorkPackage.vue';

export default {
  name: "ChangeModal",
  components: {
    issueType,
    workPackageType
  },
  computed: {
    typeComponent() {
      if(this.schema === 'work-package') {
        return 'workPackageType';
      }

      return this.schema + 'Type';
    },
    ...mapGetters('modal', {
      modalProps: 'props'
    }),
  },
  data() {
    return {
      isReady: false,
      schema: '',
      changes: {}
    }
  },
  mounted() {
    let iri = this.modalProps.iri;
    if(typeof iri === 'undefined') {
      Intranet.modals().closeCurrentModal();
    }

    CLIENT.go('/api/changes/modal').post({
      iri: iri
    }).then((result) => {
      this.schema = result.asData()['schema'];
      this.changes = result.asData()['changes']['hydra:member'];
      this.isReady = true;
    })

  },
  methods: {
    close() {
      Intranet.modals().closeCurrentModal();
    }
  }
}
</script>

<style scoped>
.info-container {
  padding: 10px;
  border-style: dashed;
  text-align: center;
  width: 100px;
}

.type-container {
  padding-left: 8px;
  width: 100%;
}
</style>
