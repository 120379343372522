import Intranet from '@/core/intranet';
import LayoutAdmin from '@/layouts/Admin.vue';
import GroupsList from '@/modules/admin/components/groups/List.vue';
import GroupsDetails from '@/modules/admin/components/groups/Details.vue';

import AdminUsersList from '@/modules/admin/components/user/List.vue';
import AdminUsersEdit from '@/modules/admin/components/user/Edit.vue';
import AdminUserQP from '@/modules/admin/components/user/new-qp.vue';
import AdminUserQPEdit from '@/modules/admin/components/user/edit-qp.vue';

import InviteUserModal from '@/modules/admin/components/user/modal/InviteUserModal.vue';
import ReportPage from '@/modules/admin/pages/ReportPage.vue';

import QualificationPlanCategories from '@/modules/admin/pages/se2a-qualification-plan-categories/se2a-qualification-plan-categories.vue';
import QualificationPlanCategoriesDetails from '@/modules/admin/pages/se2a-qualification-plan-categories/se2a-qualification-plan-categories-details.vue';

import AdminPublicationTypeList from '@/modules/admin/components/publication/AdminPublicationTypeList.vue';

import AdminExcelExport from '@/modules/admin/pages/admin-excel-export/AdminExcelExport.vue';

import FprIndex from '@/modules/admin/pages/final-project-report/Index.vue';
import FprDetails from '@/modules/admin/pages/final-project-report/Details.vue';

import FeatureFlags from '@/modules/admin/pages/feature-flags/feature-flags-index.vue';
import CreateFeatureFlagModal from '@/modules/admin/pages/feature-flags/create-feature-flag-modal.vue';

import AdminUnitCreateModal from '@/modules/admin/pages/se2a-admin-unit/modal/se2a-admin-unit-create-modal.vue';
import AdminUnitDetalsTeamsUserAdd from '@/modules/admin/pages/se2a-admin-unit/modal/se2a-admin-unit-details-team-add-user-modal.vue';
import FinalProjectReportModalAdmin from "@/modules/admin/pages/final-project-report/final-project-report-modal-admin.vue";

Intranet.modules().registerModule('admin', {
  adminRoutes: [
    {
      name: 'admin-reporting',
      path: 'reporting',
      component: ReportPage,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      }
    },


    {
      name: 'admin-publications',
      path: 'publications',
      component: () => import("@/modules/admin/pages/publications/index.vue"),
      meta: {
        layout: 'admin',
        requiresAdmin: true,
        requiresAuth: true
      }
    },
    {
      name: "admin-publications-detail",
      path: 'publications/:publicationId',
      component: () => import('@/modules/publication-next/page/se2a-publication-detail/se2a-publication-detail.vue'),
      meta: {
        layout: 'admin',
        requiresAdmin: true,
        requiresAuth: true
      },
      props: route => ({publicationId: route.params.publicationId})
    },

    {
      name: 'admin-qualification-plan-categories',
      path: 'qp-categories',
      component: QualificationPlanCategories,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      name: 'admin-qualification-plan-categories-details',
      path: 'qp-categories/:id',
      component: QualificationPlanCategoriesDetails,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      },
      props: route => ({id: route.params.id})
    },

    // === GROUPS
    {
      name: 'admin-groups-list',
      path: 'groups',
      component: GroupsList,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      name: 'admin-groups-details',
      path: 'groups/:id',
      component: GroupsDetails,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    // === USERS

    {
      name: 'admin-users-list',
      path: 'users',
      component: AdminUsersList,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      name: 'admin-users-show',
      path: 'users/:id',
      component: AdminUsersEdit,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      }
    },
    {
      name: 'admin-users-qp',
      path: 'users/:userId/newqp',
      component: AdminUserQP,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      },
      props: route => ({userId: route.params.userId})
    },
    {
      name: 'admin-users-qp-edit',
      path: 'users/:id/qp/:qpId',
      component: AdminUserQPEdit,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      },
      props: route => ({userId: route.params.id, qualificationPlanId: route.params.qpId})
    },
    {
      name: 'admin-qp-edit',
      path: 'qualification-plan-editor/:qpId',
      component: AdminUserQPEdit,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true,
      },
      props: route => ({qualificationPlanId: route.params.qpId})
    },
    // === PUBLICATION

    {
      name: 'admin-publication-type-list',
      path: 'publications',
      component: AdminPublicationTypeList,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      }
    },

    // === Excel Export

    {
      name: 'admin-excel-export',
      path: 'excel-exports',
      component: AdminExcelExport,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      }
    },

    // Final Project Report

    {
      name: 'admin-final-project-reports',
      path: 'fpr',
      component: FprIndex,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      name: 'admin-final-project-reports-details',
      path: 'fpr/:id',
      component: FprDetails,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      },
      props: route => ({fprId: route.params.id})
    },



    // Feature Flags
    {
      name: 'admin-feature-flags',
      path: 'featureFlags',
      component: FeatureFlags,
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    // Units
    {
      name: 'se2a-admin-unit-index',
      path: 'units',
      component: () => import("@/modules/admin/pages/se2a-admin-unit/se2a-admin-unit-index.vue"),
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      name: 'se2a-admin-unit-details',
      path: '/units/:id',
      component: () => import("@/modules/admin/pages/se2a-admin-unit/se2a-admin-unit-details.vue"),
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      },
      props: route => ({unitId: route.params.id})
    },
    {
      name: 'admin-qualification-plan-index',
      path: 'qualification-plan',
      component: () => import("@/modules/admin/pages/se2a-qualification-plan/se2a-qualification-plan-index.vue"),
      meta: {
        layout: 'admin',
        requiresAdmin: true,
        requiresAuth: true
      }
    },
    {
      name: 'admin-qualification-plan-detail',
      path: 'qualification-plan/:qpId',
      component: () => import("@/modules/admin/pages/se2a-qualification-plan/se2a-qualification-plan-detail.vue"),
      meta: {
        layout: 'admin',
        requiresAuth: true,
        requiresAdmin: true
      }
    },
    {
      name: 'admin-dfg',
      path: 'dfg',
      component: () => import("@/modules/admin/pages/dfg/index.vue"),
      meta: {
        layout: 'admin',
        requiresAdmin: true,
        requiresAuth: true
      }
    },
    {
      name: 'admin-dfg-show',
      path: 'dfg/:dfgId',
      component: () => import("@/modules/admin/pages/dfg/show.vue"),
      meta: {
        layout: 'admin',
        requiresAdmin: true,
        requiresAuth: true
      }
    }
  ]
});

Intranet.modals().registerModal('invite-user-modal', InviteUserModal, '1200px');
Intranet.modals().registerModal('admin-create-feature-flag', CreateFeatureFlagModal, '1200px');
Intranet.modals().registerModal('se2a-admin-unit-create-modal', AdminUnitCreateModal, '1200px');
Intranet.modals().registerModal('se2a-admin-unit-details-team-add-user', AdminUnitDetalsTeamsUserAdd, '1200px');

Intranet.modals().registerModal('final-project-report-admin', FinalProjectReportModalAdmin, '800px');
