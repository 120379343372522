<template>
  <v-card class="pa-2" flat>

    <div class="unit-select" @click="showUnitSelector = true">
      <div class="unit-select-id">
        <div class="text-center" style="width: 100%">
          <div class="project-phase" v-if="activeUnit && activeUnit.unitType.name === 'project'">
            {{ activeUnit.projectPhase === 'pb' ? 'P1/2' : activeUnit.projectPhase.toUpperCase() }}
          </div>
          {{ display.title }}
        </div>
        <v-icon>mdi-chevron-down</v-icon>
      </div>
      <div class="text-center" style="font-size: 12px">{{ display.sub }}</div>
    </div>

    <unit-selector-tree />
  </v-card>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {VERSION} from "@/config/version";
import UnitSelectorTree from "@/modules/core/components/UnitSelectorTree";
import {mapFields} from "vuex-map-fields";

export default {
  name: "DrawerUnitSelector",
  components: {UnitSelectorTree},
  data() {
    return {
      navigation: []
    }
  },
  computed: {
    ...mapGetters('unit', {
      activeUnit: 'active',
      units: 'itemsEnriched'
    }),
    ...mapFields('core', [
      'showUnitSelector'
    ]),

    display() {
      if(typeof this.activeUnit === "undefined") {
        return {
          title: 'No Unit selected',
          sub: 'Please select a unit'
        }
      }

      return {
        title: this.activeUnit.shortName,
        sub: this.activeUnit.title
      }
    },
    drawer: {
      set: function (prop) {
        this.$store.commit('core/setDrawer', prop);
      },
      get: function () {
        return this.$store.getters['core/isDrawerExtended'];
      }
    },
    version() {
      return VERSION;
    },
  },
  methods: {
    ...mapMutations('core', ['toggleAdminMode']),
    generateRouteWithUnitId(path) {
      return {
        name: path,
        params: {unitId: this.$router.currentRoute.params.unitId}
      };
    },
  }
}
</script>

<style scoped>
.unit-select {
  display: flex;
  flex-direction: column;
}

.unit-select-id {
  font-size: 26px;
  display: flex;
  justify-content: space-between;
}

.project-phase {
  background-color: rgba(213, 213, 213, 0.5);
  padding: 0 3px;
  position: absolute;
  font-size: 16px;
  border-radius: 3px;
}
</style>
