export default [
  {
    name: 'publication-list',
    path: '/publications/list',
    component: () => import('@/modules/publication/pages/Publications.vue'),
    meta: {
      requiresAuth: true,
      beforeEnter: 'publication/fetchAll'
    }
  },
  {
    name: 'publication-create-success',
    path: '/publications/create-success',
    component: () => import('@/modules/publication/pages/CreatePublicationSuccess.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'publication-policy',
    path: '/publications/policy',
    component: () => import('@/modules/publication/pages/CreatePublicationSuccess.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'publication-my-goals',
    path: '/publications/my-goals',
    component: () => import('@/modules/publication/pages/MyGoals.vue'),
    meta: {
      requiresAuth: true,
      beforeEnter: 'publicationUnitGoal/fetchMyGoals'
    }
  }
];
