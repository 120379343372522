<template>
  <div class="pa-2">
    <v-card v-if="!isLoading">

      <div>
        <div class="d-flex">
          <v-spacer />
          <v-btn @click="openCreateFeatureFlagDialog">Create Feature Flag</v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="featureFlags"
        >

          <template v-slot:item.actions="{ item }">
            <v-btn v-if="!item.active" color="green" @click="activate(item)">Activate</v-btn>
            <v-btn v-else color="red" @click="deactivate(item)">Deactivate</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import Intranet from "@/core/intranet";
import {useGetters} from "vuex-composition-helpers";
import {watchEffect} from "vue";

export default defineComponent({
  name: "feature-flags-index",
  setup() {

    const headers = [
      {text: 'Name', value: 'name'},
      {text: 'Active', value: 'active'},
      {text: 'Actions', value: 'actions'}
    ];

    const isLoading = ref(true);
    const featureFlags = ref([]);

    onMounted(() => {
      CLIENT.go(byApi('feature_flags')).get().then(result => {
        featureFlags.value = result.asPaginationResult().membersData();
      }).finally(() => {
        isLoading.value = false;
      })
    })

    const { isVisible } = useGetters('modal', ['isVisible']);

    watchEffect(() => {
      if(!isVisible.value) {
        CLIENT.go(byApi('feature_flags')).get().then(result => {
          featureFlags.value = result.asPaginationResult().membersData();
        }).finally(() => {
          isLoading.value = false;
        })
      }
    });

    const openCreateFeatureFlagDialog = () => {
      Intranet.modals().openModal('admin-create-feature-flag', {});
    };

    const activate = (item) => {
      CLIENT.go(item['@id']).patch({
        active: true
      }).finally(() => {
        CLIENT.go(byApi('feature_flags')).get().then(result => {
          featureFlags.value = result.asPaginationResult().membersData();
        }).finally(() => {
          isLoading.value = false;
          Intranet.features().loadFeatures();
        })
      })
    }

    const deactivate = (item) => {
      CLIENT.go(item['@id']).patch({
        active: false
      }).finally(() => {
        CLIENT.go(byApi('feature_flags')).get().then(result => {
          featureFlags.value = result.asPaginationResult().membersData();
        }).finally(() => {
          isLoading.value = false;
          Intranet.features().loadFeatures();
        })
      })
    }

    return {
      isLoading,
      headers,
      featureFlags,
      openCreateFeatureFlagDialog,
      activate,
      deactivate
    }
  }
});
</script>

<style scoped>

</style>
