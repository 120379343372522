function initialState() {
  return {
    visible: false,
    component: null,
    props: null
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async show({commit}, payload) {
      await commit('show', payload);
    },
    async close({commit}) {
      await commit('close');
    }
  },
  getters: {
    isVisible(state) {
      return state.visible;
    },
    props(state) {
      return state.props;
    }
  },
  mutations: {
    show(state, payload) {
      state.component = payload.component;
      state.props = payload.props;
      state.visible = true;
    },
    close(state) {
      state.visible = false;
      state.component = null;
      state.props = null;
    }
  }
}
