import ModuleService from "@/core/services/module.service";
import {Store} from "vuex";
import VueRouter from "vue-router";
import ModalService from "@/core/services/modal.service";
import Application from "@/core/application";
import initializeStore from "@/core/factories/store.factory";
import initializeRouter from "@/core/factories/router.factory";
import LoginService from "@/core/services/login.service";
import DynamicFormService from "@/core/services/dynamicForm.service";
import FeatureService from "@/core/services/feature.service";

const application = new Application();

application
  // factories
  .addServiceProvider('store', initializeStore)
  .addServiceProvider('router', initializeRouter)

  // services
  .addServiceProvider('login', () => {
    return new LoginService();
  })
  .addServiceProvider('module', () => {
    return new ModuleService();
  })
  .addServiceProvider('modal', () => {
    return new ModalService();
  })
  .addServiceProvider('dynamicForm', () => {
    return new DynamicFormService();
  })
  .addServiceProvider('feature', () => {
    return new FeatureService();
  })

  // init
  .addInitializer('initLoginChecker', function (this: Application) {
    return this.getService('login').checkLocalToken();
  }, ['init'])
  .addInitializer('initFeatureService', function (this: Application) {
    return this.getService('feature').loadFeatures();
  }, ['init'])
;

class IntranetClass {
  application(): Application {
    return application;
  }

  modules(): ModuleService {
    return application.getService('module');
  }

  store(): Store<any> {
    return application.getService('store');
  }

  router(): VueRouter {
    return application.getService('router');
  }

  modals(): ModalService {
    return application.getService('modal');
  }

  dynamicForms(): DynamicFormService {
    return application.getService('dynamicForm');
  }

  features(): FeatureService {
    return application.getService('feature');
  }
}

const Intranet = new IntranetClass();
export default Intranet;
