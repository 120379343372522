<template>
  <v-app>
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition mode="out-in">
        <router-view/>
      </transition>
    </component>
    <app-modal/>
  </v-app>
</template>

<script>

import dashboardLayout from "@/layouts/Dashboard";
import adminLayout from "@/layouts/Admin";
import authLayout from "@/layouts/Auth";

import appModal from '@/modules/modal/component/Modal';

export default {
  components: {
    dashboardLayout,
    adminLayout,
    authLayout,
    appModal
  },
  computed: {
    isRouterLoaded() {
      return this.$route.name !== null
    },
    currentLayout() {
      const layout = this.$route.meta.layout || 'dashboard'
      return layout + 'Layout'
    }
  }
};
</script>

<style scoped>
@-moz-document url-prefix() {
  html {
    scrollbar-width: none;
  }
}

body {
  margin: 0; /* remove default margin */
  scrollbar-width: none; /* Also needed to disable scrollbar Firefox */
  -ms-overflow-style: none; /* Disable scrollbar IE 10+ */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}
</style>
