import Bottle from "bottlejs";

export default class Application {

  private readonly container: Bottle;
  private tags : string[] = [];

  private isBooted = false;

  constructor() {
    const noop = (): void => {};
    this.container = new Bottle();

    this.container.service('service', noop);
    this.container.service('init', noop);
  }

  getContainer<T extends Bottle.IContainerChildren>(containerName: T): Bottle.IContainer[T] {
    if (typeof containerName === 'string' && this.container.container[containerName]) {
      return this.container.container[containerName];
    }

    return this.container.container;
  }

  getService<T extends keyof ServiceContainer>(serviceName: T): ServiceContainer[T] {
    return this.getContainer('service')[serviceName];
  }

  addInitializer(name: string, initializer: () => void, tags?: string[]): this {
    this.container.factory(`init.${name}`, initializer.bind(this));

    if(tags) {
      for (let tag of tags) {
        if(!this.tags.includes(tag)) {
          this.tags[tag] = [];
        }

        this.tags[tag].push(`init.${name}`);
      }
    }

    return this;
  }

  addServiceProvider<S extends keyof ServiceContainer>(
    name: S,
    provider: () => unknown,
  ): this {
    this.container.factory(`service.${name}`, provider.bind(this));
    return this;
  }


  boot(): Promise<unknown> {
    this.isBooted = true;
    let initServices = this.tags['init'];

    if(!initServices) return Promise.resolve();

    this.container.digest(initServices);

    let asyncInitializers: unknown[] = [];
    for (let initService of initServices) {
      const service = this.container.container[initService];
      if (service?.constructor?.name === 'Promise') {
        asyncInitializers.push(service);
      }
    }

    return Promise.all(asyncInitializers);
  }
}
