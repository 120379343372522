<!--suppress UnreachableCodeJS -->
<template>
  <v-container fluid>
    <div v-if="!isLoading">
      <tree-data-table v-if="items.length > 0" :collapsed="false" :headers="headers" :items="items">
        <template v-slot:row="{ item }">

          <!-- Status -->
          <td v-if="typeof item.status === 'string'" style="border-right: thin solid rgba(0, 0, 0, 0.12);">
            <v-chip :color="getColor(item.status)">{{ betterName(item.status) }}</v-chip>
          </td>
          <td v-if="typeof item.status === 'object'" style="border-right: thin solid rgba(0, 0, 0, 0.12);">
            <v-chip
              v-for="(status, index) of Object.entries(item.status)"
              v-bind:key="index + 'status'"
              :color="getColor(status[0])">{{ betterName(status[0]) }}
            </v-chip>
          </td>
          <td v-if="typeof item.status === 'undefined'" style="border-right: thin solid rgba(0, 0, 0, 0.12);">
            <!-- Do nothing here -->
          </td>

          <!-- End Date -->

          <td v-if="item.end" :style="item.isOverDue? 'background-color: #FFCDD2' : ''" class="text-center">
            <span class="font-weight-bold">{{ item.end }}</span>
          </td>
          <td v-if="!item.end"></td>

          <!-- Actions -->

        </template>
      </tree-data-table>
    </div>
    <div v-else>
      <v-skeleton-loader
        class="mx-auto"
        type="table"
      ></v-skeleton-loader>
    </div>
  </v-container>
</template>

<script>

import TreeDataTable from "@/modules/core/components/TreeDataTable";
import {mapActions, mapGetters} from "vuex";
import {aggregateStatus, getFlatColor} from '@/utils/status';


export default {
  name: "DeliverableOverview",
  components: {TreeDataTable},
  data() {
    return {
      headers: [
        {
          text: "Status",
          align: 'center',
          value: "",
          width: '40%',
        },
        {
          text: 'Due Date',
          align: 'center',
          value: '',
          width: '20%',
        },
      ],
      isLoading: true,
      items: [],
    }
  },
  computed: {
    ...mapGetters('core', ['activeUnit']),
    ...mapGetters('workPackage', {
      findWorkPackage: 'find'
    })
  },
  watch: {
    activeUnit: {
      immediate: true,
      handler() {
        this.isLoading = true;
        this.fetch();
      }
    }
  },
  methods: {
    ...mapActions('workPackage', {
      ensureWorkPackageLoaded: 'ensureLoadedByIri'
    }),
    betterName(name) {
      switch (name) {
        case 'progress':
          return 'in progress'
        default:
          return name;
      }
    },
    getColor(status) {
      return getFlatColor(status);
    },
    aggregateStatus(status) {
      return aggregateStatus(status)
    },
    async fetch() {
      let unit = await this.$api.go(this.activeUnit['@id']).get();
      let idCounter = 0;
      let workPackages = [];
      for (let wpIri of unit.rawData.workPackages) {
        let wp = await this.$api.go(wpIri).get();
        let wpId = idCounter++;

        let deliverables = [];
        for (let deliverableIri of wp.rawData.issues) {
          let deliverable = await this.$api.go(deliverableIri).get();
          let deliverableId = idCounter++;

          let deliverableInputs = [];
          for (let inputIri of deliverable.rawData.relations) {
            let input = await this.$api.go(inputIri).get();
            await this.ensureWorkPackageLoaded(input.rawData.workPackage);
            //console.log(input.rawData.workPackage);
            let inputWp = await this.findWorkPackage(input.rawData.workPackage);
            //console.log(inputWp)
            deliverableInputs.push({
              id: idCounter++,
              name: deliverable.rawData.name,
              nameTo: inputWp.title,
              start: wp.rawData.startDate,
              status: input.rawData.status,
              end: new Date(deliverable.rawData.dueDate).toLocaleDateString('de-DE'),
              isOverDue: input.rawData.status.delayed === 1,
              depth: 2
            })
          }

          deliverables.push({
            id: deliverableId,
            name: "Deliverable " + deliverable.rawData.name,
            children: deliverableInputs,
            status: deliverable.rawData.status,
            end: new Date(deliverable.rawData.dueDate).toLocaleDateString('de-DE'),
            isOverDue: deliverable.rawData.status === 'delayed',
            depth: 1
          });
        }

        workPackages.push({
          id: wpId,
          name: wp.rawData.title,
          children: deliverables,
          end: new Date(wp.rawData.endDate).toLocaleDateString('de-DE'),
          depth: 0
        });
      }

      this.items = workPackages;
      this.isLoading = false;
    }
  }
}
</script>

<style scoped>

</style>
