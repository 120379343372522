export default () => {
  const context = require.context('./', true, /\.\/[a-z0-9-]+\/index\.ts$/);
  console.log(context.keys());

  return context.keys().reverse().reduce((accumulator, item) => {
    const module = context(item).default;
    // @ts-ignore
    accumulator.push(module);
    return accumulator;
  }, []);
};
