<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card>
      <v-card-title>
        <span style="font-size: 16px">Invite User</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.firstname"
              label="Firstname"
              prepend-icon="mdi-text"
            ></v-text-field>
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.lastname"
              label="Lastname"
              prepend-icon="mdi-text"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.email"
              label="Email"
              prepend-icon="mdi-text"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions style="display: flex; justify-content: end">
        <v-btn @click="invite" :disabled="sent" outlined color="#4CAF50">Invite User</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import {CLIENT} from "@/api/client";

export default {
  name: "InviteUserModal",
  data() {
    return {
      valid: true,
      currentUnit: '',
      sent: false,
      form: {
        firstname: '',
        lastname: '',
        email: ''
      }
    }
  },
  computed: {
    ...mapGetters('unit', {
      unitItems: 'items'
    }),
    units() {
      return this.unitItems.map(u => {
        return {
          ...u,
          label: u.shortName + ': ' + u.title
      }});
    },
    teams() {
      return [];
    }
  },
  methods: {
    invite() {
      this.sent = true;
      CLIENT.go('/api/users/invite')
      .post({
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        email: this.form.email
      }).then(value => {
        this.$store.commit('modal/close');
        console.log(value);
        this.$router.push({name: 'admin-users-show', params: {id: value.asData().id}});
      })
    }
  }
}
</script>

<style scoped>

</style>
