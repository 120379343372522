<template>
  <div>
    <v-card>

      <v-card-text>
        <div class="text-center mt-4">
          <span style="font-size: 16px">Please enter your report/feedback here:</span>
        </div>
      </v-card-text>

      <v-card-text>
        <v-textarea placeholder="Your message" v-model="message" />
      </v-card-text>

      <v-card-actions style="display: flex; justify-content: end">
        <v-btn @click="sendMessage" :loading="isLoading" outlined color="#4CAF50">Send Message</v-btn>
        <v-btn @click="closeModal" outlined color="red">Close</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";

export default {
  name: "feedback-modal",
  data() {
    return {
      isLoading: false,
      message: ''
    }
  },
  methods: {
    sendMessage() {
      this.isLoading = true;
      CLIENT.go(byApi('feedback')).post({
        message: this.message
      }).then(() => {
        this.isLoading = false;
        this.closeModal();
      });
    },
    closeModal() {
      this.$store.dispatch('modal/close');
    }
  }
}
</script>

<style scoped>

</style>
