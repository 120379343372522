import DfgStore from './stores/dfg';
import Intranet from "@/core/intranet";

Intranet.modules().registerModule('dfg', {
  coreRoutes: [
    {
      name: 'dfg',
      path: '/dfg',
      component: () => import(/* webpackChunkName: "dfg" */ './pages/Dfg.vue'),
      meta: {
        requiresAuth: true,
        beforeEnter: 'dfg/loadMySurvey'
      }
    }
  ],
  stores: {
    dfg: DfgStore
  }
});
