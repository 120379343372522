<template>

  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          File
        </th>
        <th class="text-left">
          Actions
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="file in fpr.files" :key="file.id">
        <td>{{ file.originalFileName }}</td>
        <td>
          <v-btn @click="signFile(file)" icon><v-icon>mdi-download</v-icon></v-btn>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import {defineComponent} from "vue";
import {CLIENT} from "@/api/client";
import {download} from "@/utils/download";
import { saveAs } from 'file-saver';

export default defineComponent({
  name: "Files",
  props: {
    fpr: Object
  },
  setup(props) {

    const signFile = (item) => {
      CLIENT.go(item['@id'] + '/sign').post({}).then(result => {
        window.open(item['@id'] + '/download?token=' + result.asData()['token'], '_blank').focus();
      })
    }

    return {
      signFile
    }
  }
});
</script>

<style scoped>

</style>
