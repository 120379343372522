export class HttpError extends Error {

  response: Response;
  status: number;

  constructor(response: Response) {
    super('HTTP error ' + response.status);
    this.response = response;
    this.status = response.status;
  }
}

export class HydraError extends HttpError {

  title?: string
  description?: string


  constructor(response: Response, title?: string, description?: string) {
    super(response);
    this.title = title;
    this.description = description;

    if (this.title) {
      this.message = 'HTTP Error ' + this.status + ': ' + this.title;
    }
  }
}

export class SubmissionError extends HydraError {

  violations: any

  constructor(response: Response, title?: string, description?: string, violations?: any) {
    super(response, title, description);
    this.violations = violations;

    if (this.title) {
      this.message = 'Submission Error ' + this.status + ': ' + this.title;
    }
  }
}

