import DeliverableOverview from "@/views/DeliverableOverview.vue";

export default [
  {
    name: 'project-management-settings',
    path: 'settings',
    component: () => import('@/modules/project-management/pages/unit/Settings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'publication-goals',
    path: 'goals',
    component: () => import('@/modules/publication/pages/PublicationGoals.vue'),
    meta: {
      requiresAuth: true,
      beforeEnter: 'publicationUnitGoal/select'
    }
  },
  {
    name: 'unit-welcome',
    path: 'welcome',
    component: () => import('@/views/WelcomeView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'unit-dashboard',
    path: 'dashboard',
    component: () => import('@/modules/project-management/pages/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    name: 'work-package-list',
    path: 'workpackages',
    component: () => import('@/modules/project-management/pages/work-package/WorkPackageListPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'work-package-details',
    path: 'wp/:wpId',
    component: () => import('@/modules/project-management/pages/work-package/WorkPackageDetails.vue')
  },
  {
    name: 'unit-deliverable-overview',
    path: 'deliverables',
    component: DeliverableOverview,
    meta: {
      requiresAuth: true
    }
  },
];
