<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card
      v-if="meta.loading"
      color="primary"
      dark
    >
      <v-card-text>
        Loading...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  <v-card v-else>
    <v-card-title>
      <span style="font-size: 16px">{{ title }}</span>
      <v-select
        v-model="meta.type"
        :items="typeItems"
        item-text="name"
        item-value="value"
        style="max-width: 150px; padding-left: 4px; padding-top: 20px;"
        flat
    ></v-select>
    </v-card-title>
    <v-card-text>
      <span style="font-size: 16px; font-weight: 450">Base data</span>
      <v-divider/>
      <v-row>
        <v-col md="6" cols="12">
          <v-text-field
            v-model="form.name"
            label="Deliverable Name"
            prepend-icon="mdi-text"
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <v-menu
            v-model="show.dueDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.dueDate"
                v-bind="attrs"
                v-on="on"
                label="Due Date"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.dueDate" @input="show.dueDatePicker = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="form.description"
            auto-grow
            label="Description"
            persistent-hint
            prepend-icon="mdi-text"
          />
        </v-col>
      </v-row>

      <span style="font-size: 16px; font-weight: 450;">Explanation</span>
      <v-divider/>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="meta.explanation"
            auto-grow
            label="Explanation"
            persistent-hint
            prepend-icon="mdi-text"
            :rules="explanationRule"
          />
        </v-col>
      </v-row>

      <span style="font-size: 16px; font-weight: 450">Relations</span>
      <v-divider/>
      <div style="padding-bottom: 16px">
        <div style="display: flex; flex-direction: row; justify-content: space-between">
          <v-autocomplete
            placeholder="Search for Work Packages"
            v-model="currentComplete"
            :items="allWorkPackages"
            item-text="title"
            item-value="id"
          ></v-autocomplete>
          <div class="pt-3 pl-3">
            <v-btn @click="addRelation" outlined>Add</v-btn>
          </div>
        </div>

        <div class="relation-container">
          <div class="relation" v-for="relation in form.relations" v-bind:key="relation._id">
            <div class="ma-2">
              <span class="text-subtitle-1">{{ relation.title }}</span>
            </div>
            <div>
              <v-btn class="ma-1" @click="removeRelationEntry(relation)" depressed>Remove</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions style="display: flex; justify-content: end">
      <v-btn v-if="isModify" @click="deleteModal = true" outlined color="#E57373">
        Create Deletion Request

        <v-dialog v-model="deleteModal" persistent width="300">
          <v-card>
            <v-card-title>Deletion of Issue</v-card-title>
            <v-card-text>You are in the process of archiving this issue PERMANENTLY. It could not be reactivated afterwards. Do you want to proceed?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1" @click="confirmDelete">
                Delete
              </v-btn>
              <v-btn color="secondary darken-1" text @click.stop="deleteModal = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-btn>
      <v-btn @click="saveIssue" outlined color="#4CAF50">Create Modification Request</v-btn>
    </v-card-actions>
  </v-card>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import IssueChangeModal from "@/modules/project-management/graphql/IssueChangeModal.graphql";
import Vue from "vue";
import {CLIENT} from "@/api/client";

export default {
  name: "IssueChangeModal",
  data() {
    return {
      deleteModal: false,
      valid: true,
      explanationRule: [
        v => (v && v.length >= 20) || 'Explanation must be more than 20 characters',
      ],
      meta: {
        loading: true,
        type: '',
        workPackage: '',
        explanation: ''
      },
      show: {
        dueDatePicker: false,
      },
      form: {
        name: '',
        description: '',
        dueDate: '',
        relations: []
      },
      currentComplete: ''
    }
  },
  async mounted() {
    // set initial data
    this.meta.type = this.modalProps.type;
    this.meta.workPackage = this.modalProps.workPackage?? '';
    if (this.modalProps.action === 'modify') {
      this.meta.issue = this.modalProps.issue;

      this.form = {
        name: this.modalProps.issue.name,
        dueDate: this.modalProps.issue.dueDate,
        description: this.modalProps.issue.description,
      };
    }

    this.meta.loading = false;
  },
  apollo: {
    issue: {
      query: IssueChangeModal,
      variables() {
        return {
          id: this.modalProps.issue.id
        }
      },
      skip() {
        return this.modalProps.action !== 'modify'
      },
      result({data}) {
        Vue.set(this.meta, 'workPackage', data.issue.workPackage);
        Vue.set(this.form, 'relations', data.issue.relations.map(r => r.workPackage));
      }
    }
  },
  computed: {
    ...mapGetters('modal', {
      modalProps: 'props'
    }),
    ...mapGetters('workPackage', {
      allWorkPackages: 'items',
    }),
    isModify() {
      return this.modalProps.action === 'modify'
    },
    title() {
      switch (this.modalProps.action) {
        case 'create':
          return 'Create new ';
        case 'modify':
          return 'Modify ';
        default:
          return 'Unknown error (please contact administration)';
      }
    },
    typeItems() {
      return [
        {name: 'Deliverable', value: 'deliverable'},
        {name: 'Milestone', value: 'milestone'}
      ]
    }
  },
  methods: {
    addRelation() {
      let current = this.allWorkPackages.find(w => w.id === this.currentComplete);
      if(!current) return;

      this.form.relations.push({id: current.id, title: current.title});
      this.currentComplete = null;
    },
    removeRelationEntry(entry) {
      this.form.relations = this.form.relations.filter(value => value !== entry);
    },

    // Persistence

    confirmDelete() {
      this.deleteModal = false;
      if(!this.$refs.form.validate()) {
        return;
      }

      let iri = this.modalProps.issue['@id'] ?? this.modalProps.issue['id'];
      if(typeof iri === 'undefined') {
        return;
      }

      const data = CLIENT.go('/api/changes/commit').post({
        type: 'issue',
        action: 'delete',
        iri: iri,
        explanation: this.meta.explanation,
        new: {}
      }).then(() => {
        this.$store.dispatch('modal/close');
        this.$store.dispatch('workPackage/reload');
      })
    },
    saveIssue() {
      if(!this.$refs.form.validate()) {
        return;
      }

      let iri = this.modalProps?.issue?.['@id'] ?? this.modalProps?.issue?.['id'] ?? '';
      this.$store.dispatch('change/createChangeRequest', {
        type: 'issue',
        action: this.modalProps.action,
        iri: iri,
        explanation: this.meta.explanation,
        new: {
          workPackage: this.meta.workPackage.id,
          type: this.meta.type,
          name: this.form.name,
          dueDate: this.form.dueDate,
          description: this.form.description,
          relations: this.form.relations.map(value => value['id'])
        }
      }).then((data) => {
        this.$store.dispatch('modal/close');
        this.$store.dispatch('workPackage/reload');
      })
    }
  }
}
</script>

<style scoped>
.relation-container {
  margin-top: -1px;
  margin-left: -1px;
}

.relation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1px;
  margin-left: 1px;
  outline: 1px solid;
}
</style>
