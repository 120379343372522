<template>
  <div class="d-flex flex-column pa-2">
    <div class="issue-block">
      <div class="issue-block-header">{{ type }}</div>
      <div class="issue-block-title">{{ archived }} {{ issue.name }}</div>
      <div @click="openChangeModal" v-if="issueChanges.length > 0" class="issue-block-changes">Changes: {{ issueChanges.length }}</div>
      <div class="issue-block-due" :class="{delayed: issue.status === 'delayed'}">{{ issue.dueDate }}</div>
    </div>
    <div v-if="filteredRelations.length > 0">
      <table>
        <colgroup>
          <col style="width: 70%">
          <col style="width: 30%">
        </colgroup>
        <thead>
        <tr>
          <th class="text-left">
            Sent To
          </th>
          <th class="text-left">
            Received
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="relation in filteredRelations"
          v-bind:key="relation._id"
        >
          <td>{{ relation.isDeleted ? '[ARCHIVED] ' : '' }} {{ relation.workPackage.title }}</td>
          <td :class="relationColor(relation)">
            <v-chip
              class="mr-1"
              v-for="status in relationStatusList(relation)"
            >{{ status }}</v-chip>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Intranet from "@/core/intranet";

export default {
  name: "IssueReport",
  props: {
    issue: Object,
    filters: Object,
    changes: Array
  },
  computed: {
    issueChanges() {
      return this.changes.filter(value => value.iri === this.issue.id);
    },
    archived() {
      if(this.issue.isDeleted) {
        return '[ARCHIVED] ';
      }

      return '';
    },
    filteredRelations() {
      return this.issue.relations
        .filter(i => Object.keys(i.status).some(s => this.filters.relationStatusFilter.includes(s)))
        .filter(i => !Object.keys(i.status).some(s => this.filters.excludeRelationStatusFilters.includes(s)));
    },
    type() {
      switch(this.issue.type) {
        case 'deliverable':
          return 'Deliverable';
        case 'milestone':
          return 'Milestone';
        default:
          return 'ERROR';
      }
    }
  },
  methods: {
    openChangeModal() {
      Intranet.modals().openModal('change-modal', {
        iri: this.issue.id
      })
    },
    relationStatusList(relation) {
      return Object.keys(relation.status);
    },
    relationColor(relation) {
      let stati = Object.keys(relation.status);
      if(stati.includes('delayed')) {
        return 'delayed';
      } else if(stati.includes('finished')) {
        return 'green';
      } else if(stati.includes('awaiting-sent') && stati.includes('awaiting-received')) {
        return '';
      } else {
        return 'progress'
      }
    }
  }
}
</script>

<style scoped>
.delayed {
  background-color: rgba(255, 0, 0, 0.3) !important;
}
.green {
  background-color: rgba(64, 255, 0, 0.3) !important;
}

.progress {
  background-color: rgba(255, 240, 0, 0.3) !important;
}

.issue-block {
  display: flex;
}

.issue-block-header {
  font-weight: bold;
  padding: 8px;
  background-color: rgba(165, 199, 11, 0.5);
}

.issue-block-title {
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.08);
  flex-grow: 1;
}

.issue-block-due {
  padding: 8px;
  background-color: rgba(64, 255, 0, 0.3);
}

.issue-block-changes {
  padding: 8px;
  cursor: default;
  background-color: rgba(143, 140, 113, 0.3);
}

table{
  width:100%;
  margin-bottom:1em;
  border-collapse: collapse;
  table-layout: fixed;
}
th{
  font-weight:bold;
  background-color: rgba(221, 221, 221, 0.2);
}
th,
td{
  padding:0.5em;
  border:1px solid rgba(204, 204, 204, 0.98);
}

table tr:first-child th {
  border-top: 0;
}

</style>
