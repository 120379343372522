<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card>
      <v-card-title>
        <span style="font-size: 16px">Create Feature Flag</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="form.name"
              label="Name of the feature"
              prepend-icon="mdi-text"
            ></v-text-field>
          </v-col>
          <v-col md="6" cols="12">
            <v-checkbox
              v-model="form.active"
              label="Is this feature active?"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions style="display: flex; justify-content: end">
        <v-btn @click="create" :disabled="sent" outlined color="#4CAF50">Create Feature Flag</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import {CLIENT} from "@/api/client";
import Intranet from "@/core/intranet";

export default {
  name: "InviteUserModal",
  data() {
    return {
      valid: true,
      sent: false,
      form: {
        name: '',
        active: false
      }
    }
  },
  methods: {
    create() {
      this.sent = true;
      CLIENT.go('/api/feature_flags')
      .post({
        name: this.form.name,
        active: this.form.active
      }).then(value => {
        Intranet.features().loadFeatures();
        this.$store.commit('modal/close');
      })
    }
  }
}
</script>

<style scoped>

</style>
