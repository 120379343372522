import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import {API_PREFIX, ENTRYPOINT_API} from "@/config/entrypoint";

// Install the vue plugin
Vue.use(VueApollo)

// Config
const defaultOptions = {
  httpEndpoint: ENTRYPOINT_API + API_PREFIX + 'graphql',
  wsEndpoint: null,
  tokenName: 'user',
  persisting: false,
  websocketsOnly: false,
  ssr: false
}

const { apolloClient } = createApolloClient({
  ...defaultOptions,
})

// Create vue apollo provider
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      // fetchPolicy: 'cache-and-network',
    },
  },
  errorHandler (error) {
    // eslint-disable-next-line no-console
    console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
  },
})

export {
  apolloClient,
  apolloProvider
};
