<template>
  <div>
    <v-app-bar app clipped-left color="indigo" dark>
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>

      <img alt="SE2A Logo" height="36" src="/img/logo_SE2A.png"/>
      <v-toolbar-title class="ml-0 pl-3">
        <span class="hidden-sm-and-down">{{ version }}</span>
        <span v-if="isTestEnv"> - TEST ENVIRONMENT</span>
      </v-toolbar-title>

      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        absolute
        bottom
        color="deep-purple lighten-5"
      ></v-progress-linear>

      <v-spacer></v-spacer>

      <v-btn @click="openFeedbackModal" class="mr-2" color="green">
        Report Error/Feedback
      </v-btn>

      <!--
      <v-btn class="mr-2" outlined color="white">Help/FAQ</v-btn>
      -->

      <core-toolbar-user/>

    </v-app-bar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CoreToolbarUser from './ToolbarUser';
import {VERSION} from "@/config/version";
import {IS_TESTING_ENV} from "@/config/entrypoint";
import Intranet from "@/core/intranet";

export default {
  name: 'core-toolbar',
  components: {CoreToolbarUser},
  data: () => ({
    model: 'tab-2',
    extended: true,
    title: null,
    text: 'BLA',
    fav: true,
    message: false,
    hints: true,
    canEditGrid: false,
    toggleEdit: false,
    showExtension: true,
    loading: false,
    items: [],
    search: null,
    select: null,
  }),
  computed: {
    ...mapGetters('core', ['isLoading']),
    ...mapGetters('auth', ['user']),
    version() {
      return VERSION;
    },
    isTestEnv() {
      return IS_TESTING_ENV;
    }
  },
  watch: {
    $route(val) {
      this.title = val.name;
    },
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
  },
  methods: {
    ...mapActions('core', ['toggleDrawer']),
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter(e => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
    openFeedbackModal() {
      Intranet.modals().openModal('feedback', {});
    }
  },
};
</script>

