import {merge} from 'lodash';
import SubmissionError from "@/error/SubmissionError";
import {MUTATIONS} from "@/store/module";

export function createModule(baseModule, extendsModule) {
  return merge(baseModule, extendsModule);
}

// GETTERS

export function findByKey(key, targetKey) {
  return state => {
    const find = val => state[key].find(x => x[targetKey] === val);
    return val => Array.isArray(val) ? val.map(find) : find(val);
  }
}

export function filterByKey(key, targetKey) {
  return state => vals => {
    if (!Array.isArray(vals)) vals = [vals];
    return state[key].filter(x => vals.includes(x[targetKey]));
  }
}

// MUTATORS

export const assignState = data => state => {
  Object.assign(state, data)
};

export const setProp = key => (state, val) => {
  state[key] = val
};

// push an item onto a list
// addItem: pushTo('items')
export const pushTo = key => (state, val) => {
  state[key].push(val)
};

//TODO: Refactor error handling
export const handleError = (commit, e) => {
  if (e instanceof SubmissionError) {
    commit(MUTATIONS.SET_ERROR, e.name);
    return Promise.reject(e);
  }

  //else
  commit(MUTATIONS.SET_ERROR, e.name);
  return Promise.reject(e);
};


export const buildOperations = () => {
  let actions = {};
  actions['operation'] = async (ctx, data) => {

    let iri = data['iri'];
    let id = data['id'];

    let relations = data['relations'] ?? [];

    let entity = {};
    if(typeof id !== 'undefined') {
      entity = await ctx.dispatch('ensureLoadedById', id);
    } else if(typeof iri !== 'undefined') {
      entity = await ctx.dispatch('ensureLoadedByIri', iri);
    }

    for (const relation of Object.keys(relations)) {
      if (relation in entity) {
        if (Array.isArray(entity[relation])) {
          for (const iri of Object.keys(entity[relation])) {
            await ctx.dispatch(relations[relation]['store'] + '/operation', {...relations[relation], iri}, {root: true});
          }
        } else {
          await ctx.dispatch(relations[relation]['store'] + '/operation', {...relations[relation], iri: entity[relation]}, {root: true});
        }
      }
    }
  }

  return actions;
}

export const buildQuery = () => {
  let actions = {};
  actions['query'] = (state, getters, rootState, rootGetters) => (data) => {

    let iri = data['iri'];
    let id = data['id'];
    let relations = data['relations']?? [];

    const process = (entity) => {
      if(typeof entity === 'undefined') return undefined;
      let enriched = {...entity};

      for (const relation of Object.keys(relations)) {
        if(relation in entity) {
          if(Array.isArray(entity[relation])) {
            enriched[relation] = Object.values(entity[relation])
              .map(value => rootGetters[relations[relation]['store'] + '/query']({...relations[relation], iri: value})?? value);
          } else {
            enriched[relation] = rootGetters[relations[relation]['store'] + '/query']({...relations[relation], iri: entity[relation]})?? entity[relation];
          }
        }
      }

      return enriched;
    }

    if(typeof iri !== 'undefined') {
      return process(getters['find'](iri));
    } else if(typeof id !== 'undefined') {
      return process(getters['findById'](id));
    } else if(data['collection']?? true) {
      return getters['items'].map(value => process(value));
    }
  }

  return actions;
}


export const buildRelationSingle = (type, prop, value) => (options, rootGetters) => {
  return options['relations']?.[prop]? rootGetters[type + '/query'](value, options['relations'][prop]) : value;
}
