<template>
  <div class="my-2">
    <div>
      <v-card>
        <v-card-title>Basic Information</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column flex-sm-row">
            <div>
              <v-img
                :src="user.avatar"
                aspect-ratio="1"
                class="blue-grey lighten-4 rounded elevation-3"
                max-height="90"
                max-width="90"
              ></v-img>
              <v-btn class="mt-1" small>Edit Avatar</v-btn>
            </div>
            <div class="flex-grow-1 pt-2 pa-sm-2">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="user.firstname" label="Firstname" placeholder="firstname"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="user.lastname" label="Lastname" placeholder="lastname"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field v-model="user.email" hide-details label="Email"></v-text-field>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Membership Type
                        </th>
                        <th class="text-left">
                          Unit
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="item in user.memberships" :key="item.firstname">
                        <td>{{ item.type.name }}</td>
                        <td>{{ findUnitName(item.unit) }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div class="mt-2 d-flex">
                    <div>
                      <v-btn color="primary" @click="save">Save</v-btn>
                    </div>
                    <div class="pl-2">
                      <v-btn @click="membershipDialog = true">Add Membership</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>


      <v-expansion-panels v-model="panel" class="mt-3" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header class="title">Actions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mb-2">
              <div class="title">Reset User Password</div>
              <div class="subtitle mb-2">Sends a reset password email to the user.</div>
              <v-btn
                class="mb-2"
              >
                <v-icon left small>mdi-email</v-icon>
                Send Reset Password Email
              </v-btn>
            </div>

            <v-divider></v-divider>

            <div class="my-2">
              <div class="error--text title">Danger Zone</div>

              <div class="subtitle mt-3 mb-2">Deletes the user</div>
              <v-btn color="error" @click="deleteDialog = true">
                <v-icon left small>mdi-delete</v-icon>
                Delete User
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="title">Metadata</v-expansion-panel-header>
          <v-expansion-panel-content class="body-2">
            <span class="font-weight-bold">Created</span>
            {{ user.created }}
            <br/>
            <span class="font-weight-bold">Last Sign In</span>
            {{ user.lastSignIn }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- delete modal -->
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete User</v-card-title>
        <v-card-text>Are you sure you want to delete this user?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteDialog = false">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Details",
  data() {
    return {
      group: {}
    }
  },
  methods: {
    ...mapActions('group', {
      ensureLoaded: 'ensureLoadedByIri'
    })
  }
}
</script>

<style scoped>

</style>
