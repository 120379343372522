export function aggregateStatus(obj: any) {
  if (typeof obj === 'undefined') return 'none';
  if (typeof obj === 'string') return obj;

  let awaitCounter = 0;
  for (const [index, value] of Object.entries(obj)) {
    if (index.toLowerCase() === 'delayed') {
      return 'delayed';
    }

    if (index.toLowerCase() === 'finished') {
      return 'finished';
    }

    if (index.toLowerCase().startsWith('awaiting')) {
      awaitCounter++;
    }
  }

  if (awaitCounter == 1) {
    return 'pending';
  } else {
    return 'progress';
  }
}

export function getFlatColor(status: string): string {
  switch (status.toLowerCase()) {
    case 'progress':
      return '#F5F5F5'
    case 'awaiting-sent':
      return '#F5F5F5'
    case 'awaiting-received':
      return '#F5F5F5'
    case 'pending':
      return '#FFF9C4'
    case 'sent':
      return '#FFF9C4'
    case 'received':
      return '#FFF9C4'
    case 'finished':
      return '#C8E6C9'
    case 'delayed':
      return '#FFCDD2'
    default:
      return '#FFFFFF';
  }
}

export function getHardColor(status: string): string {
  switch (status.toLowerCase()) {
    case 'progress':
      return '#BDBDBD'
    case 'in progress':
      return '#BDBDBD'
    case 'pending':
      return '#FFEE58'
    case 'finished':
      return '#66BB6A'
    case 'delayed':
      return '#EF5350'
    default:
      return '#ffffff';
  }
}
