import Intranet from '@/core/intranet';

import MembershipStore from './stores/membership'
import {createEntityCacheModule} from "@/store/module";
import createAuthStore from "./stores/auth";

Intranet.modules().registerModule('auth', {
  stores: {
    auth: createAuthStore(),
    membership: MembershipStore,
    group: createEntityCacheModule('groups'),
    user: createEntityCacheModule('users')
  },
  coreRoutes: [
    {
      name: 'auth-signin',
      path: '/auth/signin',
      component: () => import(/* webpackChunkName: "auth-signin" */ './pages/SignIn.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      name: 'auth-forgot',
      path: '/auth/forgot-password',
      component: () => import(/* webpackChunkName: "auth-signin" */ './pages/Forgot.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      name: 'auth-reset',
      path: '/auth/reset/:token',
      component: () => import(/* webpackChunkName: "auth-reset" */ './pages/Reset.vue'),
      meta: {
        layout: 'auth'
      }
    },
    {
      name: 'auth-invite',
      path: '/auth/invite/:token',
      component: () => import (/* webpackChunkName: "auth-invite" */ './pages/Invite.vue'),
      meta: {
        layout: 'auth'
      }
    }
  ]
});
