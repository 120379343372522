import {createModule} from "@/store/utils";
import {createEntityCacheModule, MUTATIONS} from "@/store/module";
import {CLIENT} from "@/api/client";

function initialState() {
  return {}
}

export default createModule(createEntityCacheModule('issues'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetchByWorkPackage({commit, dispatch}, workPackage) {
      if(typeof workPackage === 'object') {
        workPackage = workPackage['@id'];
      }

      const issues = await CLIENT
        .go(workPackage + '/issues')
        .addPaginationEnabledModifier(false)
        .get();

      await commit(MUTATIONS.ADD_MULTIPLE, issues.asPaginationResult().membersData());

      for (const issue of issues.asPaginationResult().members()) {
        await commit(MUTATIONS.ADD, issue.asData());
      }
    },
  },
  getters: {
    findEnriched: (state, getters) => (iri) => {
      if(typeof iri === 'object') {
        iri = iri['@id'];
      }

      const issue = getters.find(iri);
      if(!issue) return undefined;
      return getters.enrich(issue);
    },
    enrich: (state, getters, rootState, rootGetters) => (issue) => {
      return {
        ...issue,
        workPackage: rootGetters['workPackage/find'](issue.workPackage) ?? issue.workPackage,
        relations: getters['findRelations'](issue) ?? [],
      }
    },
    findRelations: (state, getters, rootState, rootGetters) => (issue) => {
      return Object.entries(issue.relations).map(value => {
        return rootGetters['relation/findEnriched'](value[1]);
      }).filter(value => value);
    }
  },
  mutations: {

  }
});
