import {createModule} from "@/store/utils";
import {createOldEntityCacheModule} from "@/store/module";
import {CLIENT} from "@/api/client";
import Vue from "vue";
import _ from "lodash";


function initialState() {
  return {
    activeSurvey: undefined,
    mySurveys: [],
  }
}

export default createModule(createOldEntityCacheModule('dfg_survey_entries'), {
  namespaced: true,
  modules: [],
  state: initialState(),
  actions: {
    async loadMySurvey({commit, getters, rootGetters}) {
      let userIri = rootGetters['auth/user'].iri;
      let response = await CLIENT.go(userIri + '/surveys').get();
      await commit('setMySurveys', response.asPaginationResult().membersData());

      if(getters.isSubmittedForCurrentYear) {
        //TODO: Wait for route behaviour modding support...
      }
    }
  },
  getters: {
    isSubmittedForCurrentYear(state) {
      let currentYear = new Date().getFullYear().toString();
      return _.some(state.mySurveys, ['surveyYear', currentYear]);
    },
    mySurveys(state) {
      return state.mySurveys;
    }
  },
  mutations: {
    setMySurveys(state, surveys) {
      Vue.set(state, 'mySurveys', surveys);
      let searchYear = new Date().getFullYear() - 1;
      if(_.some(state.mySurveys, ['surveyYear', searchYear.toString()])) {
        let found = state.mySurveys.find(elem => elem.surveyYear === searchYear.toString());
        let newSurvey = {
          ...found,
          surveyYear: (searchYear + 1).toString(),
          finances: []
        };
        delete newSurvey['@sape:can'];
        delete newSurvey['@type'];
        delete newSurvey['@id'];
        delete newSurvey['id'];

        // we don't have them this year
        newSurvey['statusGroup'] = "0";
        delete newSurvey['subjectGroup'];
        delete newSurvey['subjectArea'];
        delete newSurvey['lastAcademicDegree'];

        Vue.set(state, 'activeSurvey', newSurvey);
      } else {
        Vue.set(state, 'activeSurvey', {
          statusGroup: "0",
          finances: [],
          surveyYear: (searchYear + 1).toString()
        });
      }
    }
  }
});
