<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card>
      <v-card-title>
        <span style="font-size: 16px">Add User to Team</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="form.user"
              :items="allUsers"
              item-value="iri"
              item-text="name"
              label="User"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="form.group"
              :items="availableGroups"
              item-value="@id"
              item-text="name"
              label="Group"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions style="display: flex; justify-content: end">
        <v-btn @click="invite" outlined color="#4CAF50">Add User</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import {mapGetters} from "vuex";
import Intranet from "@/core/intranet";

export default {
  name: "se2a-admin-unit-details-team-add-user-modal",
  data() {
    return {
      valid: true,
      users: [],
      availableGroups: [],
      form: {
        user: '',
        group: ''
      }
    }
  },
  mounted() {
    CLIENT.go(byApi('users')).addPaginationEnabledModifier(false).get().then((data) => {
      this.users = data.asPaginationResult().membersData();
    });

    this.$api.go(byApi('groups')).get().then((data) => {
      this.availableGroups = data.asPaginationResult().membersData();
    });

  },
  methods: {
    invite() {
      CLIENT.go(byApi('memberships')).post({
        user: this.form.user,
        team: this.modalProps.team['@id'],
        group: this.form.group
      }).then(value => {
        Intranet.modals().closeCurrentModal();
      })
    }
  },
  computed: {
    ...mapGetters('modal', {
      modalProps: 'props'
    }),
    allUsers() {
      return this.users.map(user => {
        return {
          iri: user['@id'],
          name: user.firstname + ' ' + user.lastname
        };
      });
    },
  }
}
</script>

<style scoped>

</style>
