import Intranet from "@/core/intranet";

import FinalProjectReportModal from '@/modules/final-project-report/components/final-project-report-modal.vue';
import FinalProjectReportModalAdditional from '@/modules/final-project-report/components/final-project-report-modal-additional.vue';

Intranet.modules().registerModule('final-project-report', {

});


Intranet.modals().registerModal('final-project-report-additional', FinalProjectReportModalAdditional, '800px');
Intranet.modals().registerModal('final-project-report', FinalProjectReportModal, '800px');
