import {ENTRYPOINT} from "@/config/entrypoint";
import {decode} from 'jsonwebtoken';

export default class LoginService {

  constructor() {}

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  async checkLocalToken() {
    const token = this.getToken()
    if(!token) {
      return;
    }

    if(!this.verifyUserToken(token)) {
      return;
    }

    // the local session is valid!
    await this.setToken(token);
  }

  getToken(): string|undefined {
    return localStorage.getItem('user')?.toString();
  }

  verifyUserToken(token: string): boolean {
    let expiration = decode(token).exp;
    return expiration >= (new Date().getTime() + 1) / 1000;
  }

  async loginByEmail(email: string, password: string): Promise<void> {
    const result = await fetch(ENTRYPOINT + '/authentication_token', {
      method: 'POST',
      body: JSON.stringify({email, password}),
      headers: new Headers([
        ['Accept', 'application/ld+json'],
        ['Content-Type', 'application/ld+json']
      ])
    });

    if (result.status === 401) {
      return Promise.reject("Failed to login");
    }

    let token = (await result.json()).token;
    return this.setToken(token);
  }

  setToken(token: string): Promise<void> {
    if(!this.verifyUserToken(token)) {
      return Promise.reject("Failed to verify jwt token!");
    }

    localStorage.setItem('user', token);
    console.debug(`Token set '${token}'!`)
    return Promise.resolve();
  }
}
