import {CLIENT} from "@/api/client";
import {byApi, byApiId} from "@/api/helpers";
import {Result} from "@/api/result";

export function getAllCategories(): Promise<Result> {
  return CLIENT.go(byApi('qualification_plan_categories')).get();
}

export function getPlansForUser(userId: number): Promise<Result> {
  return CLIENT.go(byApiId('users', userId) + '/plans').get();
}

export function getPlanById(id: number): Promise<Result> {
  return CLIENT.go(byApiId('qualification_plans', id)).get();
}

export function updateActivities(planId: number, activities: any[]): Promise<Result> {
  return CLIENT.go(byApiId('qualification_plans', planId)).patch({
    activities: activities.map(activity => {
      activity.cp = parseInt(activity.cp, 10);
      return activity;
    })
  });
}

export function submitPlan(id: number): Promise<Result> {
  return CLIENT.go(byApiId('qualification_plans', id) + '/submit').post({});
}
