
import {defineComponent, onMounted, ref, watch} from "vue";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import Toolbar from "@/modules/admin/components/Toolbar.vue";
import {debounce} from "lodash";
import {useRoute, useRouter} from "vue-router/composables";

export default defineComponent({
  name: "se2a-data-list",
  components: {Toolbar},
  props: {
    resource: String,
    filterStore: String,
    headers: Array,
    filters: Array,
    actions: Array
  },
  setup: (props) => {
    let options = ref({
      page: 1,
      itemsPerPage: 15,
    });

    const router = useRouter();
    const route = useRoute();
    let isLoading = ref(true);
    let filterData = ref({});

    let items = ref([]);
    let itemsCount = ref(0);

    const addParamsToLocation = (params) => {
      const co = JSON.parse(JSON.stringify(params));
      router.replace({path: route.path, query: co}).catch(()=>{});
    }

    const refresh = () => {
      addParamsToLocation({
        page: options.value.page,
        itemsPerPage: options.value.itemsPerPage,
        ...filterData.value,
      });

      load();
    }

    const load = () => {
      isLoading.value = true;
      let resource = CLIENT.go(byApi(props.resource!!));

      // pagination
      if(options.value.itemsPerPage === -1) {
        resource.addPaginationEnabledModifier(false);
      } else {
        resource.addItemsPerPageModifier(options.value.itemsPerPage ?? 10);
        resource.addPageModifier(options.value.page);
      }

      //filters
      for (const [key, val] of Object.entries(filterData.value)) {
        resource.addModifier(key, val);
      }

      resource
          .get()
          .then(result => {
            items.value = result.asPaginationResult().membersData();
            itemsCount.value = result.asPaginationResult().totalCount();
            isLoading.value = false;
          })
    };

    const firstCall = ref(true);
    const refreshOnFilterData = () => {
      if(firstCall.value) {
        firstCall.value = false;
        return;
      }

      // show page 1 when filter data is updated.
      options.value.page = 1;
      refresh();
    }

    onMounted(() => {
      const params = JSON.parse(JSON.stringify(route.query));
      options.value.page = parseInt(params.page ?? "1");
      options.value.itemsPerPage = parseInt(params.itemsPerPage ?? "15");
      delete params.page;
      delete params.itemsPerPage;
      filterData.value = params;
      load();
    });

    watch(options, () => refresh());
    watch(filterData, debounce(() => refreshOnFilterData(), 300), {deep: true});

    return {
      isLoading, items, itemsCount, options, filterData
    }
  }
});


