/*

Available Features:

 - INNET_FEATURE_FINAL_PROJECT_REPORT
 -
 -

 */

import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";

export default class FeatureService {

  private activeLocal: string[] = [];
  private activeFeatures: string[] = [];

  loadFeatures() {
    CLIENT.go(byApi('feature_flags')).get().then(result => {
      this.activeFeatures = result.asPaginationResult().membersData().filter(i => i.active).map(i => i.name);
    })
  }

  activateLocal(name: string) {
    this.activeLocal.push(name);
  }

  isFeatureActive(feature: string) {
    return this.activeFeatures.includes(feature) || this.activeLocal.includes(feature);
  }
}
