<template>

  <div>
    <author-list-type-entry
      v-for="author in model.value"
      :author="author"
      @removeEntry="removeAuthor"
      @moveUp="moveAuthorUp"
      @moveDown="moveAuthorDown"
    />

    <div>
      <v-btn
        small
        depressed
        @click="addAuthor"
      >
        Add Author
      </v-btn>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "vue";
import AuthorListTypeEntry
  from "@/modules/publication-next/dynamic-form/author-list-type/author-list-type-entry";

export default defineComponent({
  name: "author-list-type",
  components: {AuthorListTypeEntry},
  props: {
    context: {},
    model: {}
  },
  setup(props) {
    console.log(props.model)

    const addAuthor = () => {
      props.model.value.push(reactive({firstname: '', lastname: ''}));
      console.log("Add Author!");
    }

    const removeAuthor = (toRemove) => {
      props.model.value = props.model.value.filter(element => {
        return element !== toRemove
      });
    }

    const moveAuthorUp = (toUp) => {
      const index = props.model.value.findIndex(search => search === toUp);
      if(index === 0) return;
      props.model.value.splice(index - 1, 0, props.model.value.splice(index, 1)[0]);
    }

    const moveAuthorDown = (toUp) => {
      const index = props.model.value.findIndex(search => search === toUp);
      if(index >= props.model.value.length) return;
      props.model.value.splice(index + 1, 0, props.model.value.splice(index, 1)[0]);
    }


    if(props.model.value == null || props.model.value === '') {
      props.model.value = [];
      addAuthor();
    }

    return {
      addAuthor,
      removeAuthor,
      moveAuthorUp,
      moveAuthorDown
    }
  }
});
</script>

<style scoped>

</style>
