<template>
  <div class="pa-5">
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="totalItems"
        class="flex-grow-1"
      >
        <template v-slot:item.action="{ item }">
          <div class="actions">
            <v-btn :to="detailsPage(item)" icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

import CopyLabel from "@/modules/core/components/CopyLabel";
import {byApi} from "@/api/helpers.ts";

export default {
  name: 'se2a-qualification-plan-categories',
  mixins: [],
  components: {CopyLabel},
  data() {
    return {
      isLoading: true,
      items: [],
      searchQuery: '',
      totalItems: 0,
      options: {
        itemsPerPage: 10
      },
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Display Name', value: 'displayName'},
        {text: '', sortable: false, align: 'right', value: 'action'}
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    options: {
      handler() {
        this.loadItems();
      }
    }
  },
  methods: {
    loadItems() {
      this.isLoading = true;
      let resource = this.$api.go(byApi('qualification_plan_categories'));
      if (this.options.itemsPerPage !== null) {
        resource.addItemsPerPageModifier(this.options.itemsPerPage);
      }

      if (this.options.page !== null) {
        resource.addPageModifier(this.options.page);
      }

      resource.get().then(response => {
        this.items = response.asPaginationResult().members().map(value => value.rawData);
        this.totalItems = response.asPaginationResult().totalCount();
        this.isLoading = false;
      })
    },
    detailsPage(item) {
      return {name: 'admin-qualification-plan-categories-details', params: {id: item.id}}
    }
  }
};
</script>

<style scoped>

</style>
