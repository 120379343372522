<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex align-center py-3">
      <div>
        <div class="display-1">Groups</div>
        <v-breadcrumbs :items="breadcrumbs" class="pa-0 py-2"></v-breadcrumbs>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="primary">
        Create Group
      </v-btn>
    </div>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="listItems"
        :options.sync="options"
        :server-items-length="listTotalCount"
        class="flex-grow-1"
      >
        <template v-slot:item.action="{ item }">
          <div class="actions">
            <v-btn :to="detailsPage(item)" icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GroupsList",
  props: {},
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Groups',
          disabled: false,
          href: '#'
        },
        {
          text: 'List'
        }
      ],
      options: {
        itemsPerPage: 10 // default
      },
      headers: [
        {text: 'Name', value: 'displayName'},
        {text: 'Description', value: 'description'},
        {text: 'Permissions', value: 'permissions'},
        {text: '', sortable: false, align: 'right', value: 'action'}
      ]
    }
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        this.fetch({
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
        });
      }
    }
  },
  computed: {
    ...mapGetters('group', {
      listItems: 'listItems',
      listTotalCount: 'listTotalCount'
    })
  },
  methods: {
    ...mapActions('group', {
      fetch: 'fetchPage'
    }),

    detailsPage(item) {
      return {name: 'admin-group-details', params: {id: item.id}}
    }
  }
}
</script>

<style scoped>

</style>
