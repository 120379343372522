import {Resource} from "@/api/resource";
import {HydraError, SubmissionError} from "@/api/error";
import {ENTRYPOINT} from "@/config/entrypoint";

export class Client {

  baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  go<T>(uri: string): Resource<T> {
    return new Resource<T>(this.baseUrl + uri, this);
  }

  fetch(resource: string | Request, init?: RequestInit): Promise<Response> {
    const request = new Request(resource, init);
    if (!request.headers.has('Authorization')) {
      // add authorization header when set
      const user = localStorage.getItem("user");
      if (user !== null) {
        request.headers.set("Authorization", 'Bearer ' + user);
      }
    }

    // execute request
    return fetch(request);
  }

  async fetchOrThrow(resource: string | Request, init?: RequestInit): Promise<Response> {
    let response = await this.fetch(resource, init);

    if(response.status === 401) {
      throw new Error("Not authorized");
    }

    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("json") && response.status == 200 || response.status == 201) {
        // if response does not contain any json, return text instead
        return  response.clone().json().catch(() => response.text());
      }

      return response;
    } else {
      const json = await response.json();
      const error = json['hydra:description'] || json['hydra:title'] || 'An error occurred.';
      const description = json['hydra:description'];

      if (!json.violations) {
        throw new HydraError(response, error, description);
      }

      let errors = {_error: error};
      json.violations.forEach(violation =>
        errors[violation.propertyPath]
          ? (errors[violation.propertyPath] +=
          '\n' + errors[violation.propertyPath])
          : (errors[violation.propertyPath] = violation.message)
      );

      throw new SubmissionError(response, error, description, errors);
    }
  }
}

export const CLIENT: Client = new Client(ENTRYPOINT);
