<template>
<div>
  <div>
    <h2>{{ unit.shortName }} - {{ unit.title }}</h2>
  </div>
  <div>
    <work-package-report
      v-for="workPackage in mappedWorkPackages"
      v-bind:key="workPackage._id"
      :work-package="workPackage"
      :filters="filters"
      :changes="changes"
    />
  </div>
</div>
</template>

<script>
import WorkPackageReport from "@/modules/admin/components/report/WorkPackageReport";
export default {
  name: "UnitReport",
  components: {WorkPackageReport},
  props: {
    unit: Object,
    filters: Object,
    changes: Array
  },
  computed: {
    mappedWorkPackages() {
      return this.unit.teams.map(team => {
        return team.workPackages.map(wp => {
          return {
            ...wp,
            team: team
          }
        })
      }).flat();
    }
  }
}
</script>

<style scoped>
.border {
  border: #0a0a0a;
}
</style>
