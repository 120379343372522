<template>
  <div>
    <div class="wp-block">
      <div class="wp-block-header">Work Package</div>
      <div class="wp-block-title">{{ workPackage.title }}</div>
      <div @click="openChangeModal" v-if="workPackageChanges.length > 0" class="wp-block-changes">Changes: {{ workPackageChanges.length }}</div>
      <div class="wp-block-team">Team: {{ members }}</div>
    </div>
    <div v-if="filteredIssues.length >= 1" class="pl-8">
      <issue-report
        v-for="issue in filteredIssues"
        v-bind:key="issue._id"
        :issue="issue"
        :filters="filters"
        :changes="changes"
      />
    </div>
  </div>
</template>

<script>
import IssueReport from "@/modules/admin/components/report/IssueReport";
import Intranet from "@/core/intranet";
export default {
  name: "WorkPackageReport",
  components: {IssueReport},
  props: {
    workPackage: Object,
    filters: Object,
    changes: Array
  },
  computed: {
    workPackageChanges() {
      return this.changes.filter(value => value.iri === this.workPackage.id);
    },
    members() {
      return this.workPackage.team.memberships
        .map(m => m.user.firstname + ' ' + m.user.lastname)
        .join(', ');
    },
    filteredIssues() {
      return this.workPackage.issues
        .filter(i => this.filters.issueTypeFilter.includes(i.type));
    }
  },
  methods: {
    openChangeModal() {
      Intranet.modals().openModal('change-modal', {
        iri: this.workPackage.id
      })
    },
  }
}
</script>

<style scoped>
.wp-block {
  display: flex;
  padding: 8px;
}

.wp-block-header {
  font-weight: bold;
  padding: 8px;
  background-color: rgba(11, 127, 199, 0.47);
}

.wp-block-title {
  padding: 8px;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.15);
}

.wp-block-changes {
  padding: 8px;
  cursor: default;
  background-color: rgba(143, 140, 113, 0.3);
}
.wp-block-team {
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.10);
}
</style>
