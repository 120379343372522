import Intranet from '@/core/intranet';
import {Component} from "vue";

export default class ModalService {

  private readonly modals: Map<String, Component>;
  private readonly maxWidths: Map<String, String>;

  constructor() {
    this.modals = new Map();
    this.maxWidths = new Map();
  }

  registerModal(id: string, modal: Component, maxWidth: string = "100%"): boolean {
    if(this.modals.has(id)) {
      console.warn(`Modal with id "${id}" is registered already. Abort registration.`)
      return false;
    }

    this.modals.set(id, modal);
    this.maxWidths.set(id, maxWidth)
    return true;
  }

  getModalById(id: string): Component|undefined {
    return this.modals.get(id);
  }

  async openModal(id: string, params: {}) {
    let modal = this.modals.get(id);
    let maxWidth = this.maxWidths.get(id);
    if(!modal) {
     console.error('Cannot open unknown modal!');
     return;
    }

    await Intranet.store().dispatch('modal/show', {
      component: modal,
      props: {
        maxWidth: maxWidth,
        ...params
      }
    });
  }

  async closeCurrentModal() {
    await Intranet.store().dispatch('modal/close');
  }

  getRegistry() {
    return this.modals;
  }
}
