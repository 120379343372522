<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text title>
        <v-icon left>mdi-account</v-icon>
        Profile
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img src="/img/profile.png">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-list-item-subtitle>SE²A Cluster User</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn text @click="logoutInternal">
              <v-icon left>mdi-logout</v-icon>
              Logout
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item @click.stop="userSettingsDialog = true; menu = false">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>User Settings</v-list-item-title>
          </v-list-item-content>

          <!-- Dialog -->
          <v-dialog
            v-model="userSettingsDialog"
            max-width="50%"
          >
            <v-card>
              <v-card-title>
                <span class="headline">User Profile</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field :value="name" disabled label="Your email address" required/>
                      <v-divider/>
                    </v-col>
                    <v-col cols="12">
                      <h3>Change Password</h3>
                      <v-alert
                        v-if="showOldPasswordUse"
                        dense
                        border="left"
                        type="warning"
                        class="mt-2"
                      >
                        You cannot set your new password to your old password!
                      </v-alert>
                      <v-text-field v-model="form.newPassword"
                                    label="Enter new password" required
                                    type="password"
                                    @change="reset"
                      ></v-text-field>
                      <v-text-field v-model="form.newPasswordCheck"
                                    label="Confirm new password" required
                                    type="password"
                                    :rules="[v => v === form.newPassword || 'Password does not match']"
                                    @change="reset"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                      <v-alert
                        v-if="localFeatureInfo" dense border="left" class="mt-2"
                        type="info"
                      >
                        Successfully activated local feature!
                      </v-alert>
                      <div class="d-flex">
                        <v-text-field
                          v-model="localFeature"
                          label="Enable feature"
                        />
                        <v-btn class="mt-4 ml-2" color="green" @click="activateLocalFeature">Activate</v-btn>
                      </div>

                      <v-divider />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="userSettingsDialog = false">Close</v-btn>
                <v-btn :disabled="!valid" color="blue darken-1" text @click="updatePassword">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <v-list-item v-if="isCoordinator || isQpAdmin" @click.stop="goToAdmin">
          <v-list-item-icon>
            <v-icon>mdi-solar-panel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Admin Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="menu = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {CLIENT} from "@/api/client";
import Intranet from "@/core/intranet";

export default {
  name: 'CoreToolbarUser',
  data() {
    return {
      menu: false,
      userSettingsDialog: false,
      showOldPasswordUse: false,
      form: {
        newPassword: "",
        newPasswordCheck: ""
      },
      localFeatureInfo: false,
      localFeature: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isCoordinator', 'isQpAdmin']),
    name() {
      return this.user?.username;
    },
    valid() {
      if (this.form.newPassword.length <= 0) return false;
      return this.form.newPassword === this.form.newPasswordCheck;
    },
  },
  methods: {
    ...mapMutations('core', ['toggleAdminMode']),
    ...mapActions('auth', ['logout']),
    logoutInternal() {
      this.logout();
    },
    reset() {
      this.showOldPasswordUse = false;
    },
    updatePassword() {
      CLIENT.go(this.user.iri).patch({
        plainPassword: this.form.newPassword
      }).then(() => {
        // Success
        this.userSettingsDialog = false
      }).catch(() => {
        this.form = {
          newPassword: "",
          newPasswordCheck: ""
        };

        this.showOldPasswordUse = true;
      }).finally(() => {
        this.form = {
          newPassword: "",
          newPasswordCheck: ""
        };
      })


    },
    goToAdmin() {
      this.$router.push({name: 'se2a-admin'})
    },
    activateLocalFeature() {
      Intranet.features().activateLocal(this.localFeature);
      this.localFeatureInfo = true;
    }
  },
}
</script>
