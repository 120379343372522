<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-card>
      <v-card-title>
        <span style="font-size: 16px">Create Unit</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              hint="Parent Unit"
              persistent-hint
              :items="units"
              item-text="label"
              item-value="id"
              v-model="form.parent"
            />
          </v-col>
          <v-col md="4" cols="12">
            <v-text-field
              v-model="form.shortName"
              label="Short Name"
              prepend-icon="mdi-text"
            ></v-text-field>
          </v-col>
          <v-col md="8" cols="12">
            <v-text-field
              v-model="form.title"
              label="Long Name"
              prepend-icon="mdi-text"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-select
              hint="Type"
              persistent-hint
              :items="unitTypes"
              item-text="name"
              item-value="@id"
              v-model="form.unitType"
            />
          </v-col>

          <v-col cols="6">
            <v-select
              hint="Project Phase"
              persistent-hint
              :items="projectPhases"
              item-text="name"
              item-value="value"
              v-model="form.projectPhase"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions style="display: flex; justify-content: end">
        <v-btn @click="invite" :disabled="sent" outlined color="#4CAF50">Invite User</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import {CLIENT} from "@/api/client";

export default {
  name: "CreateUnitModal",
  data() {
    return {
      valid: true,
      currentUnit: '',
      sent: false,
      form: {
        title: '',
        shortName: '',
        projectPhase: '',
        parent: '',
        unitType: ''
      },
       projectPhases: [
        {name: 'Project Phase 1', value: 'p1'},
        {name: 'Project Phase 2', value: 'p2'},
        {name: 'Project Phase 1/2', value: 'pb'}
      ]
    }
  },
  computed: {
    ...mapGetters('unit', {
      unitItems: 'items'
    }),
    ...mapGetters('unitType', {
      unitTypes: 'items'
    }),
    units() {
      return this.unitItems.map(u => {
        return {
          ...u,
          label: u.shortName + ': ' + u.title
      }});
    },
    teams() {
      return [];
    }
  },
  mounted() {
    this.$store.dispatch('unitType/fetchAll');
  },
  methods: {
    invite() {
      this.sent = true;
      CLIENT.go('/api/units')
      .post({
        title: this.form.title,
        shortName: this.form.shortName,
        projectPhase: this.form.projectPhase,
        parent: this.form.parent,
        unitType: this.form.unitType
      }).then(value => {
        this.$store.commit('modal/close');
        this.$router.push({name: 'se2a-admin-unit-details', params: {id: value.asData().id}});
      })
    }
  }
}
</script>

<style scoped>

</style>
