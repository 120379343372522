import {createModule} from "@/store/utils";
import {createOldEntityCacheModule} from "@/store/module";
import {CLIENT} from "@/api/client";
import Vue from "vue";

function initialState() {
  return {
    myGoalIris: [],
    goals: []
  }
}

export default createModule(createOldEntityCacheModule('publication_unit_goal_targets'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async select({commit, dispatch, state}, ctx) {
      let result = await CLIENT.go('/api/units/' + ctx.unitId + '/goals').get();

      let goals = result.asPaginationResult().membersData();
      for(let g of goals) {
        for(let publication of g.publications) {
          await dispatch('publication/fetchByIriForce', publication, {root: true})
        }
      }

      await commit('setActiveGoals', goals);
    },
    async fetchGoalsByUnit({commit, dispatch, state}, unitIri) {
      let result = await CLIENT.go(unitIri + '/goals').get();

      let goals = result.asPaginationResult().membersData();
      for(let g of goals) {
        commit('ADD', g);
        g.publications.forEach(p => dispatch('publication/fetchByIriForce', p, {root: true}));
      }

      return goals;
    },
    async fetchMyGoals({commit, dispatch, rootGetters}) {
      let memberships = rootGetters['auth/user']['memberships'] ?? [];
      let allGoalsIiris = [];
      for (let membership of memberships) {
        let goals = await dispatch('fetchGoalsByUnit', membership.unit);
        // @ts-ignore
        allGoalsIiris.push(...goals.map(g => g['@id']));
      }

      await commit('setMyGoals', allGoalsIiris);
    }
  },
  getters: {
    myGoals: (state, getters, rootState, rootGetters) => {
      //console.log(state.myGoalIris);
      return state.myGoalIris.map(g => getters.find(g)).map(g => {
        return {
          ...g,
          publications: g.publications.map(p => rootGetters['publication/find'](p))
        }
      })
    },
    goals: (state, getters, rootState, rootGetters) => {
      return state.goals.map(g => {
        return {
          ...g,
          goal: rootGetters['publicationGoal/find'](g.goal) ?? g.goal,
          publications: g.publications.map(p => rootGetters['publication/find'](p))
        };
      })
    },
    findEnriched: (state, getters, rootState, rootGetters) => (item) => {
      return getters.enriched(getters.find(item));
    },
    enriched: (state, getters, rootState, rootGetters) => (item) => {
      return {
        ...item,
        goalName: rootGetters['publicationGoal/find'](item.goal).name ?? item.goal,
        unitShortName: rootGetters['unit/find'](item.unit).shortName ?? item.unit
      };
    }
  },
  mutations: {
    setActiveGoals(state, goals) {
      Vue.set(state, 'goals', goals);
    },
    setMyGoals(state, goals) {
      Vue.set(state, 'myGoalIris', goals);
    }
  }
});
