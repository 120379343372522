import Intranet from "@/core/intranet";

import CreatePublicationStore from './stores/createPublication';
import EditPublicationStore from './stores/editPublication';
import PublicationStore from './stores/publication';
import PublicationGoalStore from './stores/publicationGoal';

import PublicationUnitGoalStore from './stores/publicationUnitGoal';
import routes from "@/modules/publication/routes";

Intranet.modules().registerModule('publication', {
  stores: {
    createPublication: CreatePublicationStore,
    editPublication: EditPublicationStore,
    publication: PublicationStore,
    publicationGoal: PublicationGoalStore,
    publicationUnitGoal: PublicationUnitGoalStore
  },
  coreRoutes: routes
});
