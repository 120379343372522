import Intranet from '@/core/intranet';

import ChangeStore from './stores/change';
import ChangeModal from '@/modules/change/components/change-modal/ChangeModal.vue';

Intranet.modals().registerModal('change-modal', ChangeModal, '1200px');

Intranet.modules().registerModule('change', {
  stores: {
    change: ChangeStore
  },
  coreRoutes: [
    {
      name: 'change-overview',
      path: '/changes/overview',
      component: () => import(/* webpackChunkName: "changes-overview" */ './pages/ChangesOverviewPage.vue'),
      meta: {
        requiresAuth: true,
        beforeEnter: 'change/loadByUnit'
      }
    },
    {
      name: 'change-details',
      path: '/changes/:changeId',
      component: () => import(/* webpackChunkName: "changes-details" */ './pages/ChangeDetails.vue'),
      meta: {
        requiresAuth: true,
        beforeEnter: 'change/selectChange'
      }
    }
  ]
})
