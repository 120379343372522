import {Module} from "@/core/Module";

export default class ModuleService {

  private readonly modules: Map<String, Module>;

  constructor() {
    this.modules = new Map();
  }

  registerModule(id: string, module: Module): boolean {
    if(this.modules.has(id)) {
      console.warn(`Module with id "${id}" is registered already. Abort registration.`)
      return false;
    }

    this.modules.set(id, module);
    return true;
  }

  getModuleById(id: string): Module|undefined {
    return this.modules.get(id);
  }

  getRegistry() {
    return this.modules;
  }
}
