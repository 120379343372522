<template>
  <div class="d-flex justify-space-between pt-1 pb-1">
    <v-row class="pr-4">
      <v-col cols="6">
        <v-text-field
          label="Firstname"
          v-model="author.firstname"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Lastname"
          v-model="author.lastname"
        />
      </v-col>
    </v-row>
    <div class="control-group">
      <button @click="removeEntry"><v-icon small>mdi-minus-thick</v-icon></button>
      <button @click="moveUp"><v-icon small>mdi-arrow-up-thick</v-icon></button>
      <button @click="moveDown"><v-icon small>mdi-arrow-down-thick</v-icon></button>
    </div>

  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "author-list-type-entry",
  props: {
    author: {}
  },
  setup(props) {

    function removeEntry() {
      this.$emit('removeEntry', props.author)
    }

    function moveUp() {
      this.$emit('moveUp', props.author);
    }

    function moveDown() {
      this.$emit('moveDown', props.author);
    }

    return {
      removeEntry,
      moveDown,
      moveUp
    }
  }
});
</script>

<style scoped>
.control-group {
  display: flex;
  flex-direction: column;
}

.control-group button {
  border: 1px solid rgba(0, 0, 0, 0.30);
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
}

.control-group button:not(:last-child) {
  border-bottom: none;
}
</style>
