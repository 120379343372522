<template>
  <v-data-table :headers="computedHeaders" :items="flattenedItems" disable-pagination hide-default-footer>
    <template v-slot:item="props">
      <tr :style="nodeHidden(props.item)">
        <td :style="nodeStyle(props.item)" class="master-cell">
          <v-icon v-if="hasNodeChildren(props.item)" @click="toggleNode(props.item)">{{
              expandableIcon(props.item)
            }}
          </v-icon>
          <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
          <span v-if="props.item.nameTo" class="ml-1">{{ props.item.name }} <v-icon
            dense>mdi-arrow-right</v-icon> {{ props.item.nameTo }}</span>
          <span v-else class="ml-1">{{ props.item.name }}</span>
        </td>
        <slot v-bind="props" name="row"/>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "TreeDataTable",
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      flattenedItems: [],
    }
  },
  computed: {
    computedHeaders() {
      let headers = this.headers;
      if (headers.findIndex(h => h.value === 'name') < 0) {
        headers.unshift({
          text: "",
          value: "name",
          width: "40%",
          sortable: false
        });
      }

      return headers;
    }
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.flattenedItems = this.flattenNodes(this.items);
        if (!this.collapsed) {
          this.flattenedItems
            .filter(node => !node.parentNode && !node.expanded)
            .forEach(node => this.toggleNode(node, true))
        }
      }
    }
  },
  methods: {
    expandableIcon(node) {
      if (node.expanded) {
        return 'mdi-chevron-right';
      } else {
        return 'mdi-chevron-down';
      }
    },
    hasNodeChildren(node) {
      return node.hasOwnProperty('children') && node.children.length > 0;
    },
    toggleNode(node, fullExpand = false) {
      if (!node.hasOwnProperty('children') || node.children.length === 0) return;

      this.$set(node, 'expanded', !node.expanded);
      if (!node.expanded) this.collapseChildren(node);
      if (node.expanded && fullExpand) this.expandChildren(node);
    },
    expandChildren(node) {
      let vm = this;
      this.$set(node, "expanded", true);
      if (node.children) {
        node.children.forEach(child => {
          vm.expandChildren(child);
        });
      }
    },
    collapseChildren(node) {
      let vm = this;
      this.$set(node, "expanded", false);
      if (node.children) {
        node.children.forEach(child => {
          vm.collapseChildren(child);
        });
      }
    },
    nodeHidden(node) {
      if (!node.parentNode) {
        return null;
      } else if (node.parentNode.expanded) {
        return null;
      } else {
        return 'display:none;';
      }
    },
    nodeStyle(node) {
      return {
        paddingLeft: 30 * node.depth + "px"
      };
    },
    flattenNodes(nodes) {
      let flattenNodes = [];

      nodes.forEach(node => {
        flattenNodes.push(node);
        flattenNodes.push(this.flattenNode(node));
      });

      return _.flattenDeep(flattenNodes);
    },
    flattenNode(node, children) {
      children = children || [];

      if (node.children) {
        children = children.concat(node.children);
        node.children.forEach(child => {
          child.parentNode = node;
          children.splice(children.indexOf(child) + 1, 0, this.flattenNode(child));
        });
      }

      return _.flattenDeep(children);
    }
  },
}
</script>

<style scoped>
.master-cell {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: thin solid rgba(0, 0, 0, 0.2);
}
</style>
