<template>
  <div class="pa-5">
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex align-center">
        <div>
          <div class="display-1">Publication Types</div>
          <v-breadcrumbs :items="breadcrumbs" class="pa-0 py-2"></v-breadcrumbs>
        </div>
        <v-spacer></v-spacer>
        <v-btn disabled color="primary">
          Create Publication Type
        </v-btn>
      </div>

      <v-card>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="isLoading"
          :options.sync="options"
          :server-items-length="totalItems"
          class="flex-grow-1"
        >

          <template v-slot:item.requiredFields="{ item }">
            {{ item.requiredFields.map(f => f.displayName).join(', ') }}
          </template>

          <template v-slot:item.optionalFields="{ item }">
            {{ item.optionalFields.map(f => f.displayName).join(', ') }}
          </template>

          <template v-slot:item.action="{ item }">
            <div class="actions">
              <v-btn :to="detailsPage(item)" icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>

import CopyLabel from "@/modules/core/components/CopyLabel";
import {byApi} from "@/api/helpers.ts";
import Intranet from "@/core/intranet";

export default {
  name: 'AdminPublicationTypeList',
  mixins: [],
  components: {CopyLabel},
  data() {
    return {
      isLoading: true,
      items: [],
      breadcrumbs: [{
        text: 'Publication Types',
        disabled: false,
        href: '#'
      }, {
        text: 'List'
      }],
      searchQuery: '',
      totalItems: 0,
      options: {
        itemsPerPage: 10
      },
      headers: [
        {text: 'Name', align: 'left', value: 'name'},
        {text: 'Display Name', value: 'displayName'},
        {text: 'Required Fields', value: 'requiredFields'},
        {text: 'Optional Fields', value: 'optionalFields'},
        {text: '', sortable: false, align: 'right', value: 'action'}
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {
    options: {
      handler() {
        this.loadItems();
      }
    }
  },
  methods: {
    loadItems() {
      this.isLoading = true;
      let resource = this.$api.go(byApi('publication_types'));
      if (this.options.itemsPerPage !== null) {
        resource.addItemsPerPageModifier(this.options.itemsPerPage);
      }

      if (this.options.page !== null) {
        resource.addPageModifier(this.options.page);
      }

      resource.get().then(response => {
        this.items = response.asPaginationResult().members().map(value => value.rawData);
        this.totalItems = response.asPaginationResult().totalCount();
        this.isLoading = false;
      })
    },

    detailsPage(item) {
      return {name: 'admin-publication-type-show', params: {id: item.id}}
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
