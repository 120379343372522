<template>
  <v-main>
    <v-fade-transition mode="out-in">
      <router-view/>
    </v-fade-transition>
  </v-main>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'CoreContent',
  computed: {
    ...mapGetters('core', ['isLoading'])
  }
}
</script>

<style lang="scss">

</style>
