import { getField, updateField } from 'vuex-map-fields';
import {CLIENT} from "@/api/client";
import Vue from "vue";

function initialState() {
  return {
    types: undefined,
    publication: {
      type: '',
      fields: {},
      metadata: {},
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {

  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    reset(state) {
      Object.assign(state.active, initialState().publication);
    }
  }
}
