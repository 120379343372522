import {decode} from 'jsonwebtoken';
import api from '../api/auth';
import {LoginRequest} from '../types';
import {Module} from "vuex";
import Intranet from '@/core/intranet';

function initialState() {
  return {
    isAuthenticated: false,
    isLoading: false,
    error: null,
    token: null,
    relog: false
  }
}

export default function createAuthStore(): Module<any, any> {
  return {
    namespaced: true,
    state: initialState(),
    actions: {
    async checkAuth({commit, dispatch}) {
      const user = localStorage.getItem('user');
      if(user && user !== 'undefined') {

        let expiration = decode(user).exp;
        if(expiration < (new Date().getTime() + 1) / 1000) {
          await commit('LOGOUT');
          return;
        }

        await commit('AUTH_LOGIN', user);
        await dispatch('loginSuccess');
        await commit('AUTH_SUCCESS');
      }
    },
    async loginTimeout({commit, dispatch}, timeout) {
      setTimeout(async () => {
        await commit('RELOG_REQUIRED');
        await commit('LOGOUT');
      }, (timeout * 1000));
    },
    async login({commit, dispatch}, payload: LoginRequest) {
      try {
        let data = await api.login(payload)
        await commit('AUTH_LOGIN', data.token);
        await dispatch('loginSuccess');
        await commit('AUTH_SUCCESS');
      } catch (e) {
        await commit('AUTH_ERROR', e);
        throw new Error("Invalid Credentials");
      }
    },

    loginSuccess({dispatch, getters}) {
      Intranet.features().loadFeatures();

      return Promise.all([
        dispatch('loginTimeout', getters.user.exp - (new Date().getTime() / 1000)),
        dispatch('unit/load', null, {root: true})
      ])
    },

    async logout({commit}) {
      await commit('LOGOUT');
    }
  },
    getters: {
      isCoordinator(state, getters) {
        return getters.user?.roles.includes('ROLE_ADMIN') || getters.user?.roles.includes('ROLE_COORDINATOR');
      },
      isAdmin(state, getters) {
        return getters.user?.roles.includes('ROLE_ADMIN');
      },
      isQpAdmin(state, getters) {
        return getters.user?.roles.includes('ROLE_QP_ADMIN');
      },
      adminAccess(state, getters) {
        return getters.user?.roles.includes('ROLE_ADMIN') || getters.user?.roles.includes('ROLE_COORDINATOR') || getters.user?.roles.includes('ROLE_QP_ADMIN');
      },
      isRelog(state) {
        return state.relog;
      },
      isAuthenticated(state) {
        return state.isAuthenticated;
      },
      hasError(state) {
        return state.error !== null;
      },
      user(state) {
        return decode(state.token);
      }
    },
    mutations: {
      ['AUTH_LOGIN'](state, token) {
        state.error = null;
        state.token = token;
        state.relog = false;
        localStorage.setItem('user', token);
      },
      ['AUTH_SUCCESS'](state, token) {
        state.isLoading = false;
        state.isAuthenticated = true;
      },
      ['AUTH_ERROR'](state, error) {
        state.isLoading = false;
        state.error = error;
        state.isAuthenticated = false;
        state.token = null;
      },
      ['RELOG_REQUIRED'](state) {
        state.relog = true;
      },
      ['LOGOUT'](state) {
        state.isAuthenticated = false;
        state.token = null;
        localStorage.removeItem('user');
        Intranet.router().push({name: 'auth-signin'}).catch(e => { /* ignore: possible duplicate route! */})
      }
    },
  };
};
