import {createModule} from "@/store/utils";
import {createEntityCacheModule, MUTATIONS} from "@/store/module";
import {CLIENT} from "@/api/client";

function initialState() {
  return {}
}

export default createModule(createEntityCacheModule('relation_logs'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetchByRelation({commit, dispatch}, relation) {
      if(typeof relation === 'object') {
        relation = relation['@id'];
      }

      const logs = await CLIENT
        .go(relation + '/logs')
        .addPaginationEnabledModifier(false)
        .get();

      for (const log of logs.asPaginationResult().members()) {
        await commit(MUTATIONS.ADD, log.asData());
      }
    }
  },
  getters: {
    findAllByRelation: (state, getters, rootState, rootGetters) => (relation) => {

      return Object.entries(relation.logs).map(value => {
        return rootGetters['relationLog/find'](value[1]);
      }).filter(value => value);
    }
  },
  mutations: {

  }
});
