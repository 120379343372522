import {Client} from "@/api/client";
import {Result} from "@/api/result";

export class Resource<T> {

  uri: string
  withQuery?: string
  uriModifiers: URLSearchParams = new URLSearchParams();
  headers: Headers = new Headers();
  client: Client;

  constructor(uri: string, client: Client) {
    this.uri = uri;
    this.client = client;
  }

  addPreload(preload) : this {
    this.headers.append('Preload', preload);
    return this;
  }

  addFields(fields) : this {
    this.headers.append('Fields', fields);
    return this;
  }

  with(query: string) : this {
    this.withQuery = query;
    return this;
  }

  addPaginationEnabledModifier(enabled: boolean): this {
    this.uriModifiers.append('pagination', enabled ? 'true' : ' false');
    return this;
  }

  addItemsPerPageModifier(count: Number): this {
    this.uriModifiers.append('itemsPerPage', count.toString());
    return this;
  }

  addPageModifier(page: Number): this {
    this.uriModifiers.append('page', page.toString());
    return this;
  }

  addModifier(key: string, value: any): this {
    this.uriModifiers.append(key, value);
    return this;
  }

  async get(): Promise<Result<T>> {

    const response = await this.client.fetchOrThrow(this.generateUri(), {
      method: 'GET',
      headers: this.headers
    });

    return new Result<T>(this.client, response, this.generateUri());
  }

  async postRaw(body: BodyInit, contentType: string) {
    if(contentType !== null) {
      this.headers.set('Content-Type', contentType);
    }

    const response = await this.client.fetchOrThrow(this.uri, {
      method: 'POST',
      headers: this.headers,
      body: body
    });

    return new Result<T>(this.client, response, this.uri);
  }

  async post(body: T) {

    this.headers.set('Content-Type', 'application/json');
    const response = await this.client.fetchOrThrow(this.uri, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body)
    });

    return new Result<T>(this.client, response, this.uri);
  }

  async patch(body: T) {

    this.headers.set('content-type', 'application/merge-patch+json');
    const response = await this.client.fetchOrThrow(this.uri, {
      method: 'PATCH',
      headers: this.headers,
      body: JSON.stringify(body)
    });

    return new Result<T>(this.client, response, this.uri);
  }

  async delete() {
    await this.client.fetchOrThrow(this.uri, {
      method: 'DELETE',
      headers: this.headers
    });
  }

  private generateUri() {
    return this.uri + (this.uriModifiers.toString().length > 0 ? '?' + this.uriModifiers.toString() : '');
  }
}
