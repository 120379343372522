<template>

  <div class="d-flex justify-space-between">
    <h4>Field</h4>

    <div class="control-group">
      <button><v-icon>mdi-plus-thick</v-icon></button>
      <button><v-icon>mdi-minus-thick</v-icon></button>
      <button><v-icon>mdi-arrow-up-thick</v-icon></button>
      <button><v-icon>mdi-arrow-down-thick</v-icon></button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "ListComponent",
  props: {
    context: Object
  },
  setup(props) {

  }
});
</script>

<style scoped>

.control-group {
  display: flex;
  flex-direction: column;
}

.control-group button {
  border: 1px solid black; /* Green border */
  padding: 4px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
}

.control-group button:not(:last-child) {
  border-bottom: none; /* Prevent double borders */
}
</style>
