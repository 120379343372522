<template>
  <v-dialog
    v-model="showUnitSelector"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card
      class="mx-auto"
    >
      <v-toolbar color="indigo" dark>
        <img alt="SE2A Logo" height="36" src="/img/logo_SE2A.png"/>
        <v-toolbar-title class="ml-0 pl-3">
          <span class="hidden-sm-and-down">{{ version }}</span>
        </v-toolbar-title>
        <div style="padding-left: 20px;">
          <span class="hidden-sm-and-down" style="font-size: 22px; font-weight: bold">Unit Selection</span>
        </div>

        <div style="padding-left: 20px;">
          <v-btn-toggle
            group
            multiple
            v-model="selectedPhases"
          >
            <v-btn text value="p1">
              <div>
                <div>Phase 1</div>
                <div class="text-caption">2019 - 2022</div>
              </div>
            </v-btn>
            <v-btn text value="p2">
              <div>
                <div>Phase 2</div>
                <div class="text-caption">2023 - 2025</div>
              </div>
            </v-btn>
          </v-btn-toggle>
        </div>

        <v-spacer/>
        <v-toolbar-items>
          <v-btn dark text @click="showUnitSelector = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-treeview
          :active.sync="value"
          :items="items"
          activatable
          hoverable
          item-key="id"
          open-all
          ref="treeview"
          v-if="hackDestroyCreateTreeview"
        >
          <template v-slot:label="{ item }">
             {{ genTitle(item) }}
          </template>
          <template v-slot:prepend="{ item }">
            <div class="d-flex">

              <div class="project-phase" v-if="item.unitType.name === 'project'">
                {{ item.projectPhase === 'pb' ? 'P1/2' : item.projectPhase.toUpperCase() }}
              </div>

              <v-icon
                v-if="item.children"
                v-text="item.unitType.icon"
              ></v-icon>
            </div>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {VERSION} from "@/config/version";
import {mapFields} from "vuex-map-fields";

export default {
  name: "UnitSelectorTree",
  props: {
    show: Boolean
  },
  data() {
    return {
      value: [],
      selectedPhases: ['p1', 'p2'],
      hackDestroyCreateTreeview: true
    }
  },
  computed: {
    ...mapGetters('unit', {
      activeUnit: 'active',
      units: 'items'
    }),
    ...mapFields('core', [
      'showUnitSelector'
    ]),
    hasActive() {
      return typeof this.activeUnit !== "undefined";
    },
    filteredUnits() {
      return this.units.filter(unit => {
        if(unit.unitType.name !== 'project') {
          return true;
        }

        if(unit.projectPhase === null) {
          return true;
        }

        if(this.selectedPhases.length > 0 && unit.projectPhase === 'pb') {
          return true;
        }

        return this.selectedPhases.includes(unit.projectPhase);
      })
    },
    items() {
      const list = [...this.filteredUnits];
      let map = {}, node, roots = [], i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i]['id']] = i;
        list[i].children = [];
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (typeof node.parent?.id !== "undefined" && node.parent?.id !== null) {
          list[map[node.parent.id]]?.children.push(node);
        } else {
          // @ts-ignore
          roots.push(node);
        }
      }

      return roots;
    },
    version() {
      return VERSION;
    },
  },
  methods: {
    genTitle(unit) {
      if (unit.title.startsWith("ICA") || unit.title.startsWith("SE2A") || unit.title.startsWith("ResearchUnit")) {
        return unit.title;
      }

      return unit.shortName + " - " + unit.title;
    },
    selectUnit(id) {
      if (this.$router.currentRoute.name === 'welcome') {
        this.$router.push({name: 'unit-dashboard', params: {unitId: id}});
      } else if(this.$route.name === 'unit-work-packages-details') {
        this.$router.push({name: 'unit-work-packages', params: {unitId: id}});
      } else {
        this.$router.push({name: 'unit-dashboard', params: {unitId: id}});
      }

      this.show = false;
    },
  },
  watch: {
    activeUnit: {
      immediate: true,
      handler(newVal) {
        if(typeof newVal !== "undefined") {
          this.value = [newVal.id];
        }
      }
    },
    value: function (newVal, oldVar) {
      this.showUnitSelector = false;

      // handle some v-treeview edge cases
      if(newVal.length === 0 && oldVar.length === 1) {
        this.$nextTick(() => this.value = oldVar);
        return;
      }

      // duplicate navigation removal
      if(_.isEqual(newVal, oldVar) || this.hasActive && this.activeUnit.id === newVal[0]) {
        return;
      }

      this.selectUnit(newVal[0].split('/').pop());
    },
    items(newItems) {
      this.hackDestroyCreateTreeview = false;
      this.$nextTick(() => this.hackDestroyCreateTreeview = true);
    }
  }
}
</script>

<style>
.v-treeview-node__root {
  cursor: pointer;
}

.project-phase {
  background-color: rgba(213, 213, 213, 0.5);
  padding: 1px 3px;
  margin-right: 4px;
  font-size: 15px;
  border-radius: 3px;
}
</style>
