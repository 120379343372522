import { getField, updateField } from 'vuex-map-fields';
import {CLIENT} from "@/api/client";
import Vue from "vue";

function initialState() {
  return {
    types: undefined,
    actual: {},
    active: {
      currentPage: 1,
      fields: {},
      goals: {},
      title: "",
      type: "",
      units: [],
      metadata: {}
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetchTypes({commit, state}) {
      if(typeof state.types !== 'undefined') {
        return;
      }

      let result = await CLIENT.go('/api/publication_types').get();
      await commit('saveTypes', result.asPaginationResult().membersData());
    }
  },
  getters: {
    getField,
    activeType: (state) => {
      return state.types.find(t => t.name === state.active.type);
    },
    types: (state) => {
      return state.types;
    }
  },
  mutations: {
    updateField,
    reset(state) {
      Object.assign(state.actual, {});
      Object.assign(state.active, initialState().active);
    },
    saveTypes(state, data) {
      state.types = data;
    },
    loadImportData(state, data) {
      let base = initialState().active;
      Vue.set(state, 'active', {...base, ...data});
    }
  }
}
