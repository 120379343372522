import {createModule} from "@/store/utils";
import {createEntityCacheModule, MUTATIONS} from "@/store/module";
import gql from "graphql-tag";
import Vue from "vue";
import {apolloClient} from "@/vue-apollo";

function initialState() {
  return {
    active: undefined,
  }
}

const baseQuery = gql`
query BaseUnitQuery {
  units(order: [{shortName: "ASC"}]) {
    id
    title
    shortName
    projectPhase
    parent {id}
    unitType {
      name
      icon
      features
    }
  }
}
`

export default createModule(createEntityCacheModule('units'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async load({dispatch, commit}) {
      let result = await apolloClient.query({
        query: baseQuery
      });

      await commit(MUTATIONS.ADD_MULTIPLE, result.data.units);
    }
  },
  getters: {
    active(state, getters, rootState, rootGetters) {
      if(!rootState.route.params.unitId) {
        return undefined;
      }

      return getters.findByRouteId(rootState.route.params.unitId);
    }
  },
});
