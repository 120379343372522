import Intranet from "@/core/intranet";

import TextComponent from './types/TextComponent.vue';
import ListComponent from './types/ListComponent.vue';

import AuthorListComponent from './dynamic-form/author-list-type/author-list-type.vue';
import CreatePublicationStore from "@/modules/publication-next/store/createPublication.store";

Intranet.modules().registerModule('publication-next', {
  stores: {
    se2aCreatePublication: CreatePublicationStore
  },
  coreRoutes: [
    {
      name: "se2a-publication-create",
      path: "/publication/create",
      component: () => import('@/modules/publication-next/page/se2a-publication-create/se2a-publication-create.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: "se2a-publication-detail",
      path: '/publication/detail/:publicationId',
      component: () => import('@/modules/publication-next/page/se2a-publication-detail/se2a-publication-detail.vue'),
      meta: {
        requiresAuth: true
      },
      props: route => ({publicationId: route.params.publicationId})
    },
  ]
});

Intranet.dynamicForms().registerType('text', TextComponent);
Intranet.dynamicForms().registerType('list', ListComponent);
Intranet.dynamicForms().registerType('date', TextComponent);
Intranet.dynamicForms().registerType('author-list', AuthorListComponent);
