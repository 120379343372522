<template>
<div>
  <v-card v-if="isReady">
    <v-card-title>Create Plan for: {{ user.firstname }} {{ user.lastname}}</v-card-title>
    <v-card-text>

      <div>
        <v-text-field v-model="year" style="width: 200px" label="Year" />
        <v-btn @click="createPlan">Create</v-btn>
      </div>



    </v-card-text>
  </v-card>
</div>
</template>

<script>
import {computed, defineComponent, onMounted, ref} from "vue";
import {CLIENT} from "@/api/client";
import {byApi, byApiId} from "@/api/helpers";
import {getPlansForUser} from "@/modules/qualification-plan/api/api";
import {useRouter} from "vue2-helpers/vue-router";

export default defineComponent({
  props: {
    userId: {
      required: true
    }
  },
  setup(props) {

    const router = useRouter();

    const isReady = ref(false);
    const user = ref({});
    const year = ref('');
    onMounted(() => {
      CLIENT.go(byApiId('users', props.userId)).get().then(result => {
        user.value = result.asData();
        isReady.value = true;
      });
    });

    const createPlan = () => {
      CLIENT.go(byApi('qualification_plans')).post({
        status: 'success',
        user: user.value['@id'],
        year: parseInt(year.value)
      }).then(() => {
        router.push({name: 'admin-users-show', params: {id: props.userId}})
      }).catch(reason => {
        //TODO: Handle error
      })
    }

    return {
      user,
      year,
      isReady,
      createPlan
    }
  }
});
</script>

<style scoped>

</style>
