import {createModule} from "@/store/utils";
import {createEntityCacheModule, MUTATIONS} from "@/store/module";
import {CLIENT} from "@/api/client";

function initialState() {
  return {}
}

export default createModule(createEntityCacheModule('teams'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetchByUnit({commit, dispatch}, unit) {
      if(typeof unit === 'object') {
        unit = unit['@id'];
      }

      const teams = await CLIENT
        .go(unit + '/teams')
        .addPaginationEnabledModifier(false)
        .get();

      await commit(MUTATIONS.ADD_MULTIPLE, teams.asPaginationResult().membersData())
      for (const team of teams.asPaginationResult().membersData()) {
        await dispatch('membership/fetchByTeam', team, {root: true});
      }
    },
  },
  getters: {
    enrich: (state, getters, rootState, rootGetters) => (team) => {
      return {
        ...team,
        memberships: Object
          .entries(team.memberships)
          .map(value => rootGetters['membership/find'](value[1]))
      }
    },
  },
  mutations: {

  }
});
