<template>

  <v-container>

    <v-row>

      <v-col cols="3" v-for="item in categories">
        <v-card @click="navigate(item)" style="height: 100px" class="d-flex align-center">
          <v-card-text class="text-h5 font-weight-bold text-center">
            {{ item.name }}
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>

</template>

<script>
import content from "../content.json";

export default {
  name: "FormIndex",
  data() {
    return {

    }
  },
  computed: {
    categories() {
      return content;
    }
  },
  methods: {
    navigate(item) {
      this.$router.push({name: 'form-category', params: {categoryName: item.name}})
    }
  }
}
</script>

<style scoped>

</style>
