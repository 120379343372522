<template>
  <div>
    <div v-if="isReady" class="pa-5">
      <div class="d-flex justify-space-between">
        <v-select
          v-model="filters.unitFilter"
          :items="selectableUnits"
          item-text="label"
          item-value="value"
          label="Units"
          multiple
        />
        <v-select
          v-model="filters.issueTypeFilter"
          :items="filterDefinition.issueType"
          item-text="name"
          item-value="value"
          label="Issue Type"
          multiple
        />
        <v-select
          v-model="filters.relationStatusFilter"
          :items="filterDefinition.relationStatus"
          item-text="name"
          item-value="value"
          label="Relation Status"
          multiple
        />

        <v-select
          v-model="filters.excludeRelationStatusFilters"
          :items="filterDefinition.relationStatus"
          item-text="name"
          item-value="value"
          label="Exclude Relation Status"
          multiple
        />
      </div>

      <div>
        <unit-report
          v-for="unit in selectedUnits"
          v-bind:key="unit._id"
          :unit="unit"
          :filters="filters"
          :changes="changes"
        />
      </div>
    </div>

    <div class="d-flex justify-space-around pt-5" v-else>
      <h1>Loading..</h1>
    </div>
  </div>
</template>

<script>
import UnitReport from "@/modules/admin/components/report/UnitReport";
import ReportQuery from '@/modules/admin/graphql/ReportQuery.graphql';
import {mapGetters} from "vuex";
import {CLIENT} from "@/api/client";

export default {
  name: "ReportPage",
  components: {UnitReport},
  data() {
    return {
      changes: [],
      changesReady: false,
      filters: {
        unitFilter: [],
        issueTypeFilter: ['deliverable'], // default: display deliverables
        relationStatusFilter: [],
        excludeRelationStatusFilters: []
      },
      filterDefinition: {
        issueType: [
          {name: 'Deliverable', value: 'deliverable'},
          {name: 'Milestone', value: 'milestone'},
        ],
        relationStatus: [
          {name: 'Finished', value: 'finished'},
          {name: 'Delayed', value: 'delayed'},
          {name: 'Awaiting Sent', value: 'awaiting-sent'},
          {name: 'Awaiting Receive', value: 'awaiting-received'},
          {name: 'Sent', value: 'sent'},
          {name: 'Received', value: 'received'}
        ]
      }
    }
  },
  apollo: {
    units: {
      query: ReportQuery
    }
  },
  mounted() {
    CLIENT.go('/api/changes').addPaginationEnabledModifier(false).get().then((data) => {
      this.changes = data.asPaginationResult().membersData();
      this.changesReady = true;
    })
  },
  computed: {
    isReady() {
      return this.changesReady && !this.$apollo.loading;
    },
    selectedUnits() {
      return this.filters.unitFilter
        .map(unitId => this.units.find(unit => unit.id === unitId))
    },
    selectableUnits() {
      return this.units
        .filter(u => u.unitType.features.includes('project-management'))
        .map(u => {
          return {
            label: u.shortName,
            value: u.id
          }
        });
    }
  }
}
</script>

<style scoped>

</style>
