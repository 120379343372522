<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <template v-slot:prepend>
      <drawer-unit-selector />
      <v-divider></v-divider>
    </template>

    <v-list nav v-if="hasActive">
      <v-list-item
        v-for="item in navigation.unit"
        :key="item.name"
        :to="generateRouteWithUnitId(item.path)"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider v-if="hasActive" />

    <v-list nav>
      <div v-for="item in navigation.all" :key="item.name">
        <v-list-item
          v-if="!item.children"
          :to="generateRouteWithUnitId(item.path)"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          color="gray"
          v-else
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="subItem in item.children"
            :to="generateRouteWithUnitId(subItem.path)"
            :key="subItem.name"
            link
          >
            <v-list-item-title>{{ subItem.name }}</v-list-item-title>
          </v-list-item>

        </v-list-group>

      </div>
    </v-list>

    <v-divider />

  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {VERSION} from "@/config/version";
import navigationItems from '@/config/navigation';
import DrawerUnitSelector from "@/modules/core/components/DrawerUnitSelector";

export default {
  name: 'CoreDrawer',
  components: {DrawerUnitSelector},
  data() {
    return {
      search: "",
      unitSelectionShow: false
    }
  },
  computed: {
    ...mapGetters('core', {
      isAdminMode: 'isAdminMode'
    }),

    ...mapGetters('unit', {
      activeUnit: 'active',
      getUnits: 'items'
    }),
    hasActive() {
      return typeof this.activeUnit !== "undefined";
    },
    navigation() {
      let activeFeatures = [];
      if(typeof this.activeUnit !== "undefined") {
        activeFeatures = this.activeUnit.unitType.features;
      }

      return {
        unit: navigationItems.filter(value => activeFeatures.includes(value.feature)),
        all: navigationItems.filter(value => typeof value.feature === "undefined")
          .map(value => {
            if(typeof value.children !== "undefined") {
              return {
                ...value,
                children: value.children.filter(value => activeFeatures.includes(value.feature) || typeof value.feature === "undefined")
              }
            } else {
              return value;
            }
        })

      };
    },
    drawer: {
      set: function (prop) {
        this.$store.commit('core/setDrawer', prop);
      },
      get: function () {
        return this.$store.getters['core/isDrawerExtended'];
      }
    },
    version() {
      return VERSION;
    },
  },
  methods: {
    ...mapMutations('core', ['toggleAdminMode']),

    selectUnit(id) {
      if (this.$router.currentRoute.name === 'welcome') {
        this.$router.push({name: 'unit-dashboard', params: {unitId: id}});
      } else if(this.$route.name === 'unit-work-packages-details') {
          this.$router.push({name: 'unit-work-packages', params: {unitId: id}});
      } else {
        this.$router.push({name: 'unit-dashboard', params: {unitId: id}});
      }

      this.unitSelectionShow = false;
    },
    generateRouteWithUnitId(path) {
      return {
        name: path,
        params: {unitId: this.$router.currentRoute.params.unitId}
      };
    },
  }
}
</script>

<style>
.v-list-item[href="#/dfg"]  {
  background-image: linear-gradient(to right, #E0EAFC 0%, #CFDEF3  51%, #E0EAFC  100%);
}
</style>
