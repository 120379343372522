<template>
  <div>
    <v-card>
      <div class="pa-2">
        <div class="d-flex justify-space-between">
          <span>{{ item.title }}</span>
          <span>Start: {{ item.startDate }}, End: {{ item.endDate }}</span>
        </div>

        <div class="pb-2">
          Team: {{ item.team }}
        </div>
        <div>
          {{ item.description }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    item: Object
  }
});
</script>

<style scoped>

</style>
