import {createModule} from "@/store/utils";
import {createEntityCacheModule, createOldEntityCacheModule, MUTATIONS} from "@/store/module";
import {CLIENT} from "@/api/client";
import Vue from "vue";

function initialState() {
  return {
    active: undefined,
    activeUnitChanges: []
  }
}

export default createModule(createOldEntityCacheModule('changes'), {
  namespaced: true,
  modules: [],
  state: initialState(),
  actions: {
    // Called by route
    async selectChange({state, commit, dispatch}, ctx) {
      const change = await dispatch('fetchById', ctx.changeId);
      await commit('setActive', change);
    },
    async createChangeRequest({commit, dispatch}, request) {
      const data = await CLIENT.go('/api/changes/commit').post(request);
      return data.asData();
    },
    // Called by route
    async loadByUnit({state, commit, dispatch}, ctx) {
      const changes = await CLIENT.go('/api/units/' + ctx.unitId + '/changes').get();
      await commit(MUTATIONS.ADD_MULTIPLE, changes.asPaginationResult().membersData());
      await commit('setActiveUnitChanges', changes.asPaginationResult().membersData());
    }
  },
  getters: {
    active(state, getters, rootState, rootGetters) {
      return getters.enrich(state.active);
    },
    enrich: (state, getters, rootState, rootGetters) => (item) => {
      if(!item) return undefined;
      return state.active;
    },
    activeUnitItems(state, getters, rootState, rootGetters) {
      return state.activeUnitChanges;
    }
  },
  mutations: {
    setActiveUnitChanges(state, changes) {
      Vue.set(state, 'activeUnitChanges', changes);
    },
    setActive(state, active) {
      state.active = active;
    },
    clearActive(state) {
      state.active = undefined;
    }
  }
});
