<template>
  <div class="my-2">
    <div>
      <v-card>
        <v-card-title>Basic Information</v-card-title>
        <v-card-text>
          <div v-if="user.deletedAt">
            <v-alert prominent text type="warning">
              This user is deleted/archived and is only present to preserve data.
            </v-alert>
          </div>
          <div class="d-flex flex-column flex-sm-row">
            <div class="flex-grow-1 pt-2 pa-sm-2">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="user.firstname" label="Firstname" placeholder="firstname"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="user.lastname" label="Lastname" placeholder="lastname"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete v-model="user.supervisor" label="Supervisor" item-value="iri" item-text="name" :items="allUsers" />
                </v-col>

                <v-col cols="12">
                  <v-text-field v-model="user.email" hide-details label="Email"></v-text-field>

                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Group
                        </th>
                        <th class="text-left">
                          Unit
                        </th>
                        <th>
                          Team
                        </th>
                        <th>
                          Actions
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in memberships" v-bind:key="index + 'fu'">
                        <td>{{ item.group.name }}</td>
                        <td>{{ item.team.unit.shortName }}</td>
                        <td>{{ item.team.id }}</td>
                        <td>
                          <v-btn small @click="deleteMembership(item)">X</v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div class="mt-2 d-flex">
                    <div>
                      <v-btn color="primary" @click="save">Save</v-btn>
                    </div>
                    <div class="pl-2">
                      <v-btn @click="membershipDialog = true">Add Membership</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </v-card>


      <v-expansion-panels v-model="panel" class="mt-3" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header class="title">Actions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mb-2">
              <div class="title">Reset User Password</div>
              <div class="subtitle mb-2">Sends a reset password email to the user.</div>
              <v-btn
                class="mb-2"
                @click="passwordResetAction"
                :loading="passwordResetLoading"
              >
                <v-icon left small>mdi-email</v-icon>
                Send Reset Password Email
              </v-btn>
            </div>

            <v-divider></v-divider>

            <div class="my-2">
              <div class="error--text title">Danger Zone</div>

              <div class="subtitle mt-3 mb-2">Deletes the user</div>
              <v-btn color="error" @click="deleteDialog = true">
                <v-icon left small>mdi-delete</v-icon>
                Delete User
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="title">Metadata</v-expansion-panel-header>
          <v-expansion-panel-content class="body-2">
            <span class="font-weight-bold">Created</span>
            {{ user.created }}
            <br/>
            <span class="font-weight-bold">Last Sign In</span>
            {{ user.lastLoginAt }}
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header class="title">Qualification Plans</v-expansion-panel-header>
          <v-expansion-panel-content class="body-2">
            <div>
              <div>
                <v-spacer />
                <v-btn :to="{name: 'admin-users-qp', params: {userId: user.id }}">Insert Plan</v-btn>
              </div>
              <v-data-table
                :headers="qpHeaders"
                :items="qpItems"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn :to="{name: 'admin-qualification-plan-detail', params: {qpId: item.id}}">View</v-btn>
                  <v-btn :to="{name: 'admin-users-qp-edit', params: {qpId: item.id}}">Edit</v-btn>
                </template>

              </v-data-table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- Add Membership dialog -->
    <v-dialog v-model="membershipDialog">
      <v-card>
        <v-card-title class="headline">Add Membership</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select v-model="addMembershipDialog.group" :items="availableGroups" item-text="name"
                        item-value="@id"></v-select>
            </v-col>
            <v-col cols="4">
              <v-select v-model=addMembershipDialog.unit :items="units" item-text="shortName"
                        item-value="id"></v-select>
            </v-col>
            <v-col>
              <v-select v-model=addMembershipDialog.team :items="unitTeams" item-text="id"
                        item-value="@id"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="addMembership">Add</v-btn>
          <v-btn color="error" @click="membershipDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete modal -->
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Archive User</v-card-title>
        <v-card-text>Are you sure you want to archive this user?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteAction">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {byApi, byApiId} from "@/api/helpers.ts";
import {mapGetters} from "vuex";
import {mapRelations} from "@/utils/vuex";
import {CLIENT} from "@/api/client";
import {getPlansForUser} from "@/modules/qualification-plan/api/api";

export default {
  name: "Edit",
  data() {
    return {
      panel: [1],
      user: {},
      memberships: [],
      availableGroups: [],
      deleteDialog: false,
      membershipDialog: false,
      addMembershipDialog: {
        unit: '',
        group: '',
        team: ''
      },
      users: [],
      availableTeams: [],
      qpHeaders: [
        { text: 'Year', value: 'year' },
        { text: 'Status', value: 'status' },
        { text: 'Total CP', value: 'totalCp' },
        { text: 'Actions', value: 'actions' }
      ],
      qpItems: [],
      passwordResetLoading: false,
    }
  },

  mounted() {
    this.$api.go(byApiId('users', this.$route.params.id)).get().then((data) => {
      this.user = data.rawData;
      this.user.supervisor = data.rawData.supervisor?.['@id'] ?? ''; // nullable
    })

    CLIENT.go(byApiId('users', this.$route.params.id) + '/memberships').get().then((data) => {
      this.memberships = data.asPaginationResult().membersData();
    })

    CLIENT.go(byApi('users')).addPaginationEnabledModifier(false).get().then((data) => {
      this.users = data.asPaginationResult().membersData();
    });

    this.$api.go(byApi('groups')).get().then((data) => {
      this.availableGroups = data.asPaginationResult().membersData();
    });

    CLIENT.go(byApi('teams')).addPaginationEnabledModifier(false).get().then((data) => {
      this.availableTeams = data.asPaginationResult().membersData();
    });

    getPlansForUser(this.$route.params.id).then(result => {
      this.qpItems = result.asPaginationResult().membersData();
    })
  },
  computed: {
    ...mapGetters('unit', {
      findUnit: 'find',
      units: 'items'
    }),
    allUsers() {
      return this.users.map(user => {
        return {
          iri: user['@id'],
          name: user.firstname + ' ' + user.lastname
        };
      });
    },
    unitTeams() {
      return this.availableTeams.filter(t => t.unit === this.addMembershipDialog.unit);
    }
  },
  methods: {
    save() {
      this.$api.go(this.user['@id']).patch({
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        email: this.user.email,
        supervisor: this.user.supervisor === '' ? null : this.user.supervisor
      }).then(value => {
        this.user = value.rawData;
      })
    },
    addMembership() {
      this.membershipDialog = false;
      this.$api.go(byApi('memberships')).post({
        user: this.user['@id'],
        team: this.addMembershipDialog.team,
        group: this.addMembershipDialog.group
      }).then(() => {

        //reload user
        this.$api.go(byApiId('users', this.$route.params.id)).get().then((data) => {
          this.user = data.rawData;
        })

        //reload memberships
        CLIENT.go(byApiId('users', this.$route.params.id) + '/memberships').get().then((data) => {
          this.memberships = data.asPaginationResult().membersData();
        })
      })
    },
    deleteMembership(item) {
      CLIENT.go(item['@id']).delete().then(() => {
        //reload memberships
        CLIENT.go(byApiId('users', this.$route.params.id) + '/memberships').get().then((data) => {
          this.memberships = data.asPaginationResult().membersData();
        })
      })
    },
    passwordResetAction() {
      this.passwordResetLoading = true;
      CLIENT.go(byApi('reset_password/request')).post({
        email: this.user['email']
      }).finally(() => {
        this.passwordResetLoading = false;
      })
    },
    deleteAction() {
      this.deleteDialog = false;
      CLIENT.go(this.user['@id']).delete().finally(() => {
        this.$router.push({name: 'admin-users-list'})
      })
    }
  },
}
</script>

<style scoped>

</style>
