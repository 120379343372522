import Intranet from '@/core/intranet';

import FormIndex from './page/FormIndex.vue';
import FormCategory from './page/FormCategory.vue';

Intranet.modules().registerModule('form', {
  coreRoutes: [
    {
      name: 'form-index',
      component: FormIndex,
      path: '/form',
    },
    {
      name: 'form-category',
      component: FormCategory,
      path: '/form/:categoryName'
    }
  ]
});
