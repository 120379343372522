<template>
  <div class="pa-5">
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex align-center">
        <div>
          <div class="display-1">Users</div>
          <v-breadcrumbs :items="breadcrumbs" class="pa-0 py-2"></v-breadcrumbs>
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="openInviteUserModal" color="primary">
          Invite User
        </v-btn>
      </div>

      <v-card>
        <se2a-data-list
            resource="users"
            :headers="headers"
            :filters="filters"
        >
          <template v-slot:item.email="{ item }">
            <div class="d-flex align-center py-1">
              <v-avatar class="elevation-1 grey lighten-3" size="32">
                <v-img :src="item.avatar"/>
              </v-avatar>
              <div class="ml-1 caption font-weight-bold">
                <copy-label :text="item.email"/>
              </div>
            </div>
          </template>


          <template v-slot:item.name="{ item }">
            {{ item.firstname }} {{ item.lastname }}
          </template>


          <template v-slot:item.role="{ item }">
            <v-chip
                v-for="role in item.roles"
                class="font-weight-bold"
                label
                small
            >{{ role }}
            </v-chip>
          </template>

          <template v-slot:item.action="{ item }">
            <div class="actions">
              <v-btn :to="detailsPage(item)" icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </template>
        </se2a-data-list>
      </v-card>
    </div>
  </div>
</template>

<script>

import CopyLabel from "@/modules/core/components/CopyLabel";
import {byApi} from "@/api/helpers.ts";
import Intranet from "@/core/intranet";
import _ from "lodash";
import Se2aDataList from "@/components/se2a-data-list.vue";

export default {
  name: 'UsersList',
  mixins: [],
  components: {Se2aDataList, CopyLabel},
  data() {
    return {
      breadcrumbs: [{
        text: 'Users',
        disabled: false,
        href: '#'
      }, {
        text: 'List'
      }],
      headers: [
        {text: 'Email', value: 'email'},
        {text: 'Name', align: 'left', value: 'name'},
        {text: 'Roles', value: 'role'},
        {text: '', sortable: false, align: 'right', value: 'action'}
      ],
      filters: [
        {name: 'E-Mail', on: 'email'},
        {name: 'Firstname', on: 'firstname'},
        {name: 'Lastname', on: 'lastname'}
      ]
    };
  },
  methods: {
    openInviteUserModal() {
      Intranet.modals().openModal('invite-user-modal', {});
    },
    detailsPage(item) {
      return {name: 'admin-users-show', params: {id: item.id}}
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
