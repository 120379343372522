import Vue from 'vue';
import Vuetify from 'vuetify';

// Import Style and Font
import 'vuetify/dist/vuetify.min.css';

import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(Vuetify);

const vuetify = new Vuetify();

export default vuetify;
