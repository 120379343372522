<template>
  <div class="my-2 pa-5">
    <div>
      <v-card>

        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step step="1">
              Submit FPR
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2">
              Formal Review
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              Coordinator Review
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4">
              Success
            </v-stepper-step>
          </v-stepper-header>


          <v-stepper-items>
            <v-stepper-content step="1">
              <v-alert type="info">The Team have to submit a FPR before we can review them!</v-alert>
            </v-stepper-content>

            <v-stepper-content step="2">

              <div class="mb-2">
                <Files :fpr="fpr" />

                <div class="d-flex pt-4" style="gap: 8px">
                  <v-spacer />
                  <v-btn color="white" @click="upload">Upload</v-btn>
                  <v-btn color="green" @click="accept">Accept</v-btn>

                  <v-dialog
                    v-model="dialog"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="red" @click="openDialog">Decline OUT</v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Decline Final Project Report
                      </v-card-title>

                      <v-card-text>
                        <p>Please enter an explanation:</p>
                        <v-textarea v-model="declineMsg" label="Decline Explanation"></v-textarea>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="decline">
                          Decline
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>

              </div>

            </v-stepper-content>

            <v-stepper-content step="3">

              <div class="mb-2">
                <Files :fpr="fpr" />

                <div class="d-flex pt-4" style="gap: 8px">
                  <v-spacer />
                  <v-btn color="white" @click="upload">Upload</v-btn>
                  <v-btn color="green" @click="accept">Accept</v-btn>

                  <v-dialog
                    v-model="dialog"
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="red" @click="openDialog">Decline</v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Decline Final Project Report
                      </v-card-title>

                      <v-card-text>
                        <p>Please enter an explanation:</p>
                        <v-textarea v-model="declineMsg" label="Decline Explanation"></v-textarea>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="decline">
                          Decline
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>

              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div class="mb-2">
                <h3>SUCCESS!</h3>
                <Files :fpr="fpr"/>
              </div>

            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, computed} from "vue";
import {CLIENT} from "@/api/client";
import {byApiId} from "@/api/helpers";
import Files from "@/modules/admin/pages/final-project-report/Files";
import {useRouter} from "vue2-helpers/vue-router";
import Intranet from "@/core/intranet";

export default defineComponent({
  name: "Details",
  components: {Files},
  props: {
    fprId: {
      required: true
    }
  },
  setup(props) {

    const router = useRouter();

    const fpr = ref({});
    const loadItem = () => {
      CLIENT.go(byApiId('final_project_reports', props.fprId)).get().then(result => {
        fpr.value = result.asData();
      })
    }

    onMounted(() => {
      loadItem();
    });

    const currentStep = computed(() => {
      switch (fpr.value.status) {
        case 'submit':
          return 1;
        case 'formal-review':
          return 2;
        case 'coordinator-review':
          return 3;
        case 'success':
          return 4;
      }
    })

    const upload = () => {
      Intranet.modals().openModal('final-project-report-admin', {reportIri: byApiId('final_project_reports', props.fprId), update: loadItem});
    }

    const accept = () => {
      CLIENT.go(byApiId('final_project_reports', props.fprId + '/accept')).post({}).then(result => {
        fpr.value = result.asData();
      })
    }

    const decline = () => {
      dialog.value = false;
      CLIENT.go(byApiId('final_project_reports', props.fprId + '/decline')).patch({
        message: declineMsg.value
      }).then(result => {
        router.push({name: 'admin-final-project-reports'})
      })
    }

    const dialog = ref(false);
    const declineMsg = ref("");

    const openDialog = () => {
      dialog.value = true;
    }

    return {
      openDialog,
      dialog,
      declineMsg,
      fpr,
      currentStep,
      accept,
      decline,
      upload
    }
  }
});
</script>

<style scoped>

</style>
