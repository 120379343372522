import Intranet from "@/core/intranet";
import {createCoreModule} from './stores/core';
import UnitStore from './stores/unit';
import FeatureIncompleteModal from '@/modules/core/components/FeatureIncompleteModal.vue';
import FeedbackModal from '@/modules/core/components/modals/FeedbackModal.vue';

Intranet.modules().registerModule('core', {
  stores: {
    core: createCoreModule(),
    unit: UnitStore
  }
});

Intranet.modals().registerModal('feature-incomplete', FeatureIncompleteModal, '500px');
Intranet.modals().registerModal('feedback', FeedbackModal, '500px');
