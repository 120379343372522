import {Result} from "@/api/result";
import {Resource} from "@/api/resource";

export class Paginator<T> {

  private result: Result

  constructor(result: Result) {
    this.result = result;
  }

  totalCount(): number {
    return this.result.rawData['hydra:totalItems'];
  }

  members(): Result<T>[] {
    return this.result.rawData['hydra:member']
      .map(i => new Result(this.result.client, i, i['@iri']));
  }

  membersData(): any {
    return this.result.rawData['hydra:member'];
  }

  firstPage(): Resource<T> {
    return this.result.client.go(this.result.rawData['hydra:first']);
  }

  lastPage(): Resource<T> {
    return this.result.client.go(this.result.rawData['hydra:last']);
  }

  nextPage(): Resource<T> {
    return this.result.client.go(this.result.rawData['hydra:next']);
  }
}
