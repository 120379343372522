import {byApi} from "@/api/helpers";
import {CLIENT} from "@/api/client";

export function createVuexApiService(name: string) {

  return {

    // Operations
    create(item) {
      return CLIENT.go(byApi(name)).post(item);
    },
    delete(item) {
      return CLIENT.go(item['@id']).delete();
    },
    update(item) {
      return CLIENT.go(item['@id']).patch(item);
    },

    // Fetch
    fetchAll(params) {
      let resource = CLIENT.go(byApi(name));

      if (params.hasOwnProperty('pagination')) {
        resource.addPaginationEnabledModifier(params.pagination);
      }

      if (params.page) {
        resource.addPageModifier(params.page);
      }

      if (params.itemsPerPage) {
        resource.addItemsPerPageModifier(params.itemsPerPage);
      }

      if (params.unit) {
        resource.addModifier('unit', params.unit);
      }

      return resource.get();
    },

    fetchSubresource(iri, subresource) {
      return CLIENT.go(`${iri}/${subresource}`)
        .addPaginationEnabledModifier(false)
        .get()
    },

    fetchByIri(iri) {
      return CLIENT.go(iri).get();
    }
  };
}
