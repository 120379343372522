<template>
  <div>
    <v-card>
      <div class="pa-2">
        <div class="d-flex justify-space-between">
          <span>{{ item.workPackage }}</span>
          <span>Due: {{ item.dueDate }}</span>
        </div>

        <div>
          <v-simple-table dense v-if="item.relations.length > 0">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Relation with
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="relation in item.relations" :key="relation">
                <td>{{ relation }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div v-else>
            This issue has no relations
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    item: Object
  }
});
</script>

<style scoped>

</style>
