<template>
  <v-form lazy-validation ref="form" v-model="valid" v-if="!$apollo.loading">
    <v-card
      v-if="meta.loading"
      color="primary"
      dark
    >
      <v-card-text>
        Loading...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span style="font-size: 16px">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <span style="font-size: 16px; font-weight: 450">Base data</span>
        <v-divider/>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.title"
              label="Work Package Title"
              prepend-icon="mdi-text"
            ></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-menu
              v-model="show.startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.startDate"
                  v-bind="attrs"
                  v-on="on"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.startDate" @input="show.startDatePicker = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="6" cols="12">
            <v-menu
              v-model="show.endDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.endDate"
                  v-bind="attrs"
                  v-on="on"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.endDate" @input="show.endDatePicker = false"></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="form.description"
              auto-grow
              label="Description"
              persistent-hint
              prepend-icon="mdi-text"
            />
          </v-col>

          <v-col cols="12">
            <v-select v-model="form.team"
                      :items="availableTeams"
                      item-text="value"
                      item-value="id"
                      label="Team"
                      prepend-icon="mdi-account-supervisor"
            ></v-select>
          </v-col>
        </v-row>

        <span style="font-size: 16px; font-weight: 450;">Explanation</span>
        <v-divider/>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="meta.explanation"
              auto-grow
              label="Explanation"
              persistent-hint
              prepend-icon="mdi-text"
              :rules="explanationRule"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions style="display: flex; justify-content: end">
        <v-btn v-if="isModify" @click="deleteModal = true" outlined color="#E57373">
          Create Deletion Request

          <v-dialog v-model="deleteModal" persistent width="300">
            <v-card>
              <v-card-title>Deletion of Work Package</v-card-title>
              <v-card-text>You are in the process of archiving this work package PERMANENTLY. It could not be reactivated afterwards. Do you want to proceed?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" @click="confirmDelete">
                  Delete
                </v-btn>
                <v-btn color="secondary darken-1" text @click.stop="deleteModal = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
        <v-btn @click="saveWorkPackage" outlined color="#4CAF50">Create Modification Request</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapGetters} from "vuex";
import unitDashboardQuery from "@/modules/project-management/graphql/UnitDashboard.graphql";
import {CLIENT} from "@/api/client";
import ConfirmDelete from "@/modules/admin/components/ConfirmDelete";

export default {
  name: "WorkPackageChangeModal",
  components: {ConfirmDelete},
  data() {
    return {
      deleteModal: false,
      valid: true,
      explanationRule: [
        v => (v && v.length >= 20) || 'Explanation must be more than 20 characters',
      ],
      show: {
        startDatePicker: false,
        endDatePicker: false
      },
      meta: {
        loading: true,
        type: '',
        workPackage: '',
        explanation: ''
      },
      form: {
        title: '',
        startDate: '',
        endDate: '',
        description: '',
        team: ''
      }
    }
  },
  apollo: {
    unit: {
      query: unitDashboardQuery,
      variables() {
        return {
          id: this.activeUnit.id
        }
      }
    }
  },
  async mounted() {
    this.meta.loading = false;

    if (this.modalProps.action === 'modify') {
      this.meta.workPackage = this.modalProps.workPackage.id;

      this.form = {
        title: this.modalProps.workPackage.title,
        description: this.modalProps.workPackage.description,
        startDate: this.modalProps.workPackage.startDate,
        endDate: this.modalProps.workPackage.endDate,
        team: this.modalProps.workPackage.team.id
      };
    }
  },
  computed: {
    ...mapGetters('modal', {
      modalProps: 'props'
    }),
    ...mapGetters('unit', {
      activeUnit: 'active'
    }),
    ...mapGetters('workPackage', {
      allWorkPackages: 'items'
    }),
    availableTeams() {
      return this.unit.teams.map(t => {
        return {
          id: t.id,
          value: t.memberships.map(m => m.user.firstname + ' ' + m.user.lastname).join(', ')
        }
      });
    },
    isModify() {
      return this.modalProps.action === 'modify'
    },
    title() {
      switch (this.modalProps.action) {
        case 'create':
          return 'Create new Work Package';
        case 'modify':
          return 'Modify Work Package';
        default:
          return 'Unknown error (please contact administration)';
      }
    }
  },
  methods: {
    confirmDelete() {
      this.deleteModal = false;

      if(!this.$refs.form.validate()) {
        return;
      }

      if(typeof this.meta.workPackage === 'undefined') {
        return;
      }

      const data = CLIENT.go('/api/changes/commit').post({
        type: 'work-package',
        action: 'delete',
        iri: this.meta.workPackage,
        explanation: this.meta.explanation,
        new: {}
      }).then(() => {
        this.$store.dispatch('modal/close');
        this.$router.push({name: 'unit-dashboard', params: {unitId: this.$router.currentRoute.params.unitId}})
      })
    },
    saveWorkPackage() {
      if(!this.$refs.form.validate()) {
        return;
      }

      this.$store.dispatch('change/createChangeRequest', {
        type: 'work-package',
        action: this.modalProps.action,
        iri: this.meta.workPackage?? '',
        explanation: this.meta.explanation,
        new: {
          title: this.form.title,
          description: this.form.description,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          team: this.form.team
        }
      }).then((data) => {
        this.$store.dispatch('modal/close');
        this.$store.dispatch('workPackage/reload');
      })
    }
  }
}
</script>

<style scoped>

</style>
