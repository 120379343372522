<template>
  <v-text-field
    :label="context.displayName"
    v-model="model"
  />
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "TextComponent",
  props: {
    context: Object,
    model: Object
  }
});
</script>

<style scoped>

</style>
