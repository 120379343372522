import {LoginRequest} from "@/modules/auth/types";
import {ENTRYPOINT} from "@/config/entrypoint";

export default {
  async login(login: LoginRequest) {
    const result = await fetch(ENTRYPOINT + '/authentication_token', {
      method: 'POST',
      body: JSON.stringify(login),
      headers: new Headers([
        ['Accept', 'application/ld+json'],
        ['Content-Type', 'application/ld+json']
      ])
    });

    if (result.status === 401) {
      throw new Error("Failed to login");
    }

    return result.json();
  }
}
