import {createModule} from "@/store/utils";
import {createEntityCacheModule, MUTATIONS} from "@/store/module";
import {CLIENT} from "@/api/client";

function initialState() {
  return {}
}

export default createModule(createEntityCacheModule('relations'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async fetchByWorkPackage({commit, dispatch}, workPackage) {
      if(typeof workPackage === 'object') {
        workPackage = workPackage['@id'];
      }

      const relations = await CLIENT
        .go(workPackage + '/relations')
        .addPaginationEnabledModifier(false)
        .get();

      for (const relation of relations.asPaginationResult().members()) {
        await commit(MUTATIONS.ADD, relation.asData());
      }
    },
    async fetchByIssue({commit, dispatch}, issue) {
      if(typeof issue === 'object') {
        issue = issue['@id'];
      }

      const relations = await CLIENT
        .go(issue + '/relations')
        .addPaginationEnabledModifier(false)
        .get();

      await commit(MUTATIONS.ADD_MULTIPLE, relations.asPaginationResult().members());
      for (const relation of relations.asPaginationResult().members()) {
        await commit(MUTATIONS.ADD, relation.asData());
        await dispatch('fetchAndEnrich', relation.asData());
      }
    },
    async fetchAndEnrich({commit, dispatch}, relationIri) {
      if(typeof relationIri === 'object') {
        relationIri = relationIri['@id'];
      }

      const relation = await dispatch('fetchByIri', relationIri);
      await dispatch('workPackage/fetchByIri', relation.workPackage, {root: true});
      await dispatch('issue/fetchByIri', relation.issue, {root: true});
    }
  },
  getters: {
    findEnriched: (state, getters) => (iri) => {
      const relation = getters.find(iri);
      if(!relation) return undefined;
      return getters.enrich(relation);
    },
    enrich: (state, getters, rootState, rootGetters) => (relation) => {
      return {
        ...relation,
        issue: rootGetters['issue/find'](relation.issue) ?? relation.issue,
        workPackage: rootGetters['workPackage/find'](relation.workPackage) ?? relation.workPackage,
      }
    }
  },
  mutations: {

  }
});
