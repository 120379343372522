import {Component} from "vue";

export default class DynamicFormService {

  private readonly types: Map<String, Component>;

  constructor() {
    this.types = new Map();
  }

  registerType(id: string, type: Component): boolean {
    if(this.types.has(id)) {
      console.warn(`Type with id "${id}" is registered already. Abort registration.`)
      return false;
    }

    this.types.set(id, type);
    return true;
  }

  getTypeComponentById(id: string): Component|undefined {
    return this.types.get(id);
  }
}
