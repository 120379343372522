<template>
  <div>
    <core-toolbar/>

    <v-navigation-drawer app clipped v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Intranet Administration
          </v-list-item-title>
          <v-list-item-subtitle>
            Version: {{ version }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="{ name: item.route }"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <core-content />
  </div>
</template>

<script>
import CoreToolbar from "@/modules/core/components/Toolbar";
import {VERSION} from "@/config/version";
import CoreContent from "@/modules/core/components/Content";
import {mapGetters} from "vuex";

export default {
  name: "LayoutAdmin",
  components: {CoreContent, CoreToolbar},
  computed: {
    drawer: {
      set: function (prop) {
        this.$store.commit('core/setDrawer', prop);
      },
      get: function () {
        return this.$store.getters['core/isDrawerExtended'];
      }
    },
    ...mapGetters('auth', ['isAdmin', 'isQpAdmin']),
    items() {
      if(this.isAdmin) {
        return [
          {title: 'Users', icon: 'mdi-account-multiple', route: 'admin-users-list'},
          {title: 'Units', icon: 'mdi-home-group', route: 'se2a-admin-unit-index'},
          {title: 'Groups', icon: 'mdi-server-security', route: 'admin-groups-list'},
          {title: 'Reporting', icon: 'mdi-chart-box', route: 'admin-reporting'},
          {title: 'Publications', icon: 'mdi-book-open', route: 'admin-publications'},
          {title: 'Publication Types', icon: 'mdi-book-open', route: 'admin-publication-type-list'},
          {title: 'Excel Export', icon: 'mdi-microsoft-excel', route: 'admin-excel-export'},
          {title: 'Qualification Plan Categories', icon: 'mdi-shape', route: 'admin-qualification-plan-categories'},
          {title: 'Qualification Plans', icon: 'mdi-shape', route: 'admin-qualification-plan-index'},
          {title: 'Final Project Report', icon: 'mdi-chart-timeline', route: 'admin-final-project-reports'},
          {title: 'Feature Flags', icon: 'mdi-flag', route: 'admin-feature-flags'},
          {title: 'DFG', icon: 'mdi-account-multiple', route: 'admin-dfg'}
        ]
      } else {
        if(this.isQpAdmin) {
          return [
            {title: 'Qualification Plans', icon: 'mdi-shape', route: 'admin-qualification-plan-index'}
          ]
        } else {
          return [
            {title: 'Reporting', icon: 'mdi-chart-box', route: 'admin-reporting'},
            {title: 'Final Project Report', icon: 'mdi-chart-timeline', route: 'admin-final-project-reports'},
          ]
        }
      }
    },
    version() {
      return VERSION;
    }
  }
}
</script>

<style scoped>

</style>
