import {createLoadingModule} from "@/store/module";
import {createModule} from "@/store/utils";
import {getField, updateField} from 'vuex-map-fields';

const CORE_MUTATIONS = {
  LOAD_UNIT_SETTINGS: 'LOAD_TREE',
  SET_UNIT_SETTINGS: 'SET_TREE'
};

export function createCoreModule() {
  return createModule(createLoadingModule(), {
    namespaced: true,
    state: {
      drawer: true,
      showUnitSelector: false,
      adminMode: false,
      loading: false,
    },
    getters: {
      getField,
      isLoading(state) {
        return state.loading;
      },
      isDrawerExtended(state) {
        return state.drawer;
      },
      getSettings(state) {
        return state.unitSettings;
      },
      isAdminMode(state) {
        return state.adminMode;
      },
      activeUnit(state, getters, rootState, rootGetters) {
        let routeUnitId = rootState["route"].params.unitId;
        return rootGetters["unit/findById"](routeUnitId);
      }
    },
    mutations: {
      updateField,
      toggleAdminMode(state) {
        state.adminMode = !state.adminMode;
      },
      toggleDrawer(state) {
        state.drawer = !state.drawer;
      },
      setDrawer(state, open) {
        state.drawer = open;
      },
      startLoading(state) {
        state.loading = true;
      },
      stopLoading(state) {
        state.loading = false;
      },
      [CORE_MUTATIONS.SET_UNIT_SETTINGS]: (state, settings) => {
        state.unitSettings = settings
      }
    },
    actions: {
      toggleDrawer({commit}) {
        commit('toggleDrawer');
      },
      setDrawer({commit}, open) {
        commit('setDrawer', open)
      }
    }
  });
}
