import { render, staticRenderFns } from "./edit-qp.vue?vue&type=template&id=a25cd19e&scoped=true&"
import script from "./edit-qp.vue?vue&type=script&setup=true&lang=ts&"
export * from "./edit-qp.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a25cd19e",
  null
  
)

export default component.exports