<template>
  <v-container>

    <v-row>

      <v-col cols="3" v-for="item in items">
        <v-card :href="item.file" style="height: 100px" class="d-flex align-center">
          <v-card-text class="text-h5 font-weight-bold text-center">
            {{ item.name }}
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import content from "../content.json";

export default {
  name: "FormCategory",
  computed: {
    items() {
      let name = this.$route.params['categoryName'];

      let category = content.find(test => test.name === name);
      return category.content;
    }
  }
}
</script>

<style scoped>

</style>
