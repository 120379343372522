// @ts-ignore
import WorkPackageBaseQuery from '@/modules/project-management/graphql/WorkPackageBase.graphql';
import {createModule} from "@/store/utils";
import {createEntityCacheModule, MUTATIONS} from "@/store/module";
import {apolloClient} from "@/vue-apollo";
import {Store} from "vuex";

function initialState() {
  return {
    active: undefined,
    reload: 0
  }
}

export default createModule(createEntityCacheModule('work_packages'), {
  namespaced: true,
  state: initialState(),
  actions: {
    async load({dispatch, commit}) {
      let result = await apolloClient.query({
        query: WorkPackageBaseQuery
      });

      await commit(MUTATIONS.ADD_MULTIPLE, result.data.workPackages);
    },
    reload({commit}) {
      commit('RELOAD');
    }
  },
  getters: {
    reloadEvent(state) {
      return state.reload;
    },
    findUnitIdByWorkPackageRouteId: (state, getters) => (id) => {
      return getters.findByRouteId(id)?.team?.unit?._id;
    },
    active(state, getters, rootState) {
      if(!rootState.route.params.wpId) {
        return undefined;
      }

      return getters.findByRouteId(rootState.route.params.wpId);
    }
  },
  mutations: {
    ['RELOAD']: (state, item) => {
      state.reload++;
    }
  }
});

export function storeListener(store: Store<any>): void {
  store.subscribe(async mutation => {
    if(mutation.type == 'auth/AUTH_LOGIN') {
      await store.dispatch('workPackage/load');
    }
  })
}
