<template>
  <div>
    <v-container fluid style="max-width: 600px" v-if="!isLoading" class="pa-5">
      <v-select
        v-model="form.type"
        :items="availableExports"
        item-text="title"
        item-value="value"
      />

      <v-select
        v-if="form.type === 'exportv1'"
        v-model="form.units"
        :items="units"
        item-text="shortName"
        item-value="@id"
        multiple
      />

      <v-btn width="100%" :loading="isRequesting" @click="doRequest">Request Export</v-btn>
    </v-container>
    <div class="d-flex justify-space-around pt-5" v-else>
      <h1>Loading..</h1>
    </div>
  </div>
</template>

<script>
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import {download} from "@/utils/download";

export default {
  name: "AdminExcelExport",
  data() {
    return {
      isLoading: true,
      isRequesting: false,
      units: [],
      form: {
        type: '',
        units: []
      },
      availableExports: [
        {title: 'Export V1', value: 'exportv1'},
        {title: 'Export Publications', value: 'exportpublications'}
      ]
    }
  },
  created() {
    CLIENT.go(byApi('units')).addPaginationEnabledModifier(false).get().then(value => {
      this.units = value.asPaginationResult().membersData();
      this.isLoading = false;
    }).catch(reason => {
      // TODO: CATCH EXCEPTION
    })

  },
  methods: {
    doRequest() {
      this.isRequesting = true;
      CLIENT.go(byApi('excel_exports')).post(this.form).then(value => {
        value.rawData.blob().then(blob => {
          download(blob, this.form.type + ".xlsx", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          this.isRequesting = false;
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
