import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Intranet from "@/core/intranet";
import Vue from 'vue';

// Plugins
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/moment';
import './plugins/donut';
import './plugins/clipboard';

import VueExcelEditor from 'vue-excel-editor';
Vue.use(VueExcelEditor);

import {sync} from 'vuex-router-sync';

// Main App

import App from './App.vue';
// set global api client
import {CLIENT} from "@/api/client";
import {apolloProvider} from './vue-apollo'
import initializeModules from "@/modules";
import {IS_TESTING_ENV} from "@/config/entrypoint";



const init = async () => {

  console.info("Booting intranet application...");

  if(IS_TESTING_ENV) {
    document.title = "SE²A Intranet - TEST ENVIRONMENT";
  }

  initializeModules();
  await Intranet.application().boot();
  console.info("Intranet successfully booted!");



  Vue.prototype.$api = CLIENT;
  sync(Intranet.store(), Intranet.router());
  await Intranet.store().dispatch('auth/checkAuth');

  new Vue({
    vuetify,
    i18n,
    router: Intranet.router(),
    store: Intranet.store(),
    apolloProvider: apolloProvider,
    provide: Intranet.application().getContainer('service'),
    render: h => h(App)
  }).$mount('#app');
};

init().then(r => {
  console.log("Intranet successfully initialized!");
});


