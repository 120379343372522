<template>
  <div class="background d-flex text-center flex-column flex-md-row flex-grow-1">
    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto">
        <slot></slot>
      </div>
      <div class="overline mt-4">{{ version }}</div>
    </div>
  </div>
</template>

<script>
import {VERSION} from "@/config/version";

export default {
  name: "Auth",
  computed: {
    version() {
      return VERSION;
    }
  }
}
</script>

<style scoped>

.background {
  background-color: #e1f5fe;
}

.layout-content {
  max-width: 480px;
  width: 100%;
}

</style>
