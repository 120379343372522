<template>
  <div>
    <v-card>

      <v-card-text>
        <div class="text-center mt-4">
          <span style="font-size: 24px">{{ modalProps.text }}</span>
        </div>
      </v-card-text>

      <v-card-actions style="display: flex; justify-content: end">
        <v-btn @click="closeModal" outlined color="#4CAF50">Close</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Intranet from "@/core/intranet";
import {mapGetters} from "vuex";

export default {
  name: "FeatureIncompleteModal",
  computed: {
    ...mapGetters('modal', {
      modalProps: 'props'
    })
  },
  methods: {
    closeModal() {
      Intranet.modals().closeCurrentModal();
    }
  }
}
</script>

<style scoped>

</style>
