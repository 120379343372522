<template>
  <div>
    <core-toolbar/>
    <core-drawer/>
    <core-content/>
  </div>
</template>

<script>

import CoreToolbar from "../modules/core/components/Toolbar";
import CoreDrawer from "../modules/core/components/Drawer";
import CoreContent from "../modules/core/components/Content";

export default {
  name: "LayoutDashboard",
  components: {CoreContent, CoreDrawer, CoreToolbar},
  computed: {},
  watch: {}
}
</script>

<style scoped>

</style>
