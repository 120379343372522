<template>
  <v-dialog v-model="isVisible" :max-width="maxWidth">
    <component :is="component"/>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Modal",
  computed: {
    ...mapState('modal', {
      component: 'component',
      props: 'props'
    }),
    isVisible: {
      get() {
        return this.$store.getters['modal/isVisible'];
      },
      set(val) {
        if(!val) {
          this.$store.dispatch('modal/close');
        }
      }
    },
    maxWidth() {
      return this.props?.maxWidth ?? '100%';
    }
  }
}
</script>

<style scoped>

</style>
