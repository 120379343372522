<template>
<div class="pa-5">

  <div class="pb-2">
    <div>
      <div class="display-1">Final Project Reports</div>
    </div>
  </div>

  <v-card v-if="isLoaded">
    <v-data-table
      :headers="headers"
      :items="reports"
    >

      <template v-slot:item.status="{ item }">
        <v-chip>{{ item.status }}</v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn :to="detailsPage(item)" icon>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import {useRouter} from "vue2-helpers/vue-router";

export default defineComponent({
  name: "Index",
  setup() {
    const router = useRouter();

    const headers = [
      {text: 'Unit', value: 'team.unit.shortName'},
      {text: 'Team', value: 'team.membershipName'},
      {text: 'Status', value: 'status'},
      {text: 'Actions', value: 'actions'}
    ]

    const isLoaded = ref(false);
    const reports = ref([]);
    onMounted(() => {
      CLIENT.go(byApi('final_project_reports')).addPaginationEnabledModifier(false).get().then(result => {
        reports.value = result.asPaginationResult().membersData();
        isLoaded.value = true;
      })
    })

    const detailsPage = (item) => {
      return {name: 'admin-final-project-reports-details', params: {id: item.id}};
    }


    return {
      headers,
      isLoaded,
      reports,
      detailsPage
    }
  }
});
</script>

<style scoped>

</style>
